import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { DeprecatedThreeColumnPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

export function ThreeColumnPanel({
  blok,
}: {
  blok: DeprecatedThreeColumnPanelStoryblok
}) {
  return (
    <div {...storyblokEditable(blok)} className="py-12">
      <CenteredContent>
        <div className="flex flex-col gap-8">
          <div className="max-w-prose md:mx-auto md:text-center">
            {blok.header.map((headerBlok) => (
              <StoryblokComponent key={headerBlok._uid} blok={headerBlok} />
            ))}
          </div>
          <div
            {...storyblokEditable(blok)}
            className="grid grid-cols-1 gap-6 md:grid-cols-3"
          >
            {blok.columns.map((column) => (
              <StoryblokComponent key={column._uid} blok={column} />
            ))}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}
