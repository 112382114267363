import { datadogRum } from '@datadog/browser-rum'
import { LDFlagSet } from '@launchdarkly/node-server-sdk'
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect } from 'react'
import { useUser } from 'src/deprecated/api/user-queries'
import { environment } from 'src/deprecated/lib/environment.mjs'
import { useAnalytics } from 'src/deprecated/lib/report/AnalyticsProvider'

/**
 * This wrapper updates the LD context when we have the user qid.
 * This is because we fetch the user qid on the client. So the initial
 * LDPRovider render will never have the user qid and needs to be
 * updated once we've fetched the user.
 *
 * This will go away when we fetch the user on the server and pass it to the
 * _app component as pageProps.
 */
const ContextManager = ({ children }: { children: ReactNode }) => {
  const ldClient = useLDClient()
  const { data: user } = useUser()

  const { identify } = useAnalytics()

  useEffect(() => {
    if (!user) return

    const identifyUser = async () => {
      identify(user, { flags: ldClient?.allFlags() })
    }

    ldClient?.on('change', identifyUser)

    return () => {
      ldClient?.off('change', identifyUser)
    }
  }, [ldClient, user, identify])

  useEffect(() => {
    if (!user?.qid || !user?.email) return

    ldClient?.identify({
      kind: 'user',
      key: user.qid,
      email: user.email,
    })
  }, [user?.qid, ldClient, user?.email])

  return children
}

export function FeatureFlagProvider({
  children,
  initialState,
}: {
  children: ReactNode
  initialState: LDFlagSet
}) {
  const LAUNCHDARKLY_SDK_CLIENT_SIDE_ID =
    environment.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID ?? ''
  const { data: user } = useUser()

  return (
    <LDProvider
      options={{
        bootstrap: initialState,
        disableSyncEventPost: true,
        flushInterval: 2000,
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key: string, { value }) => {
              datadogRum.addFeatureFlagEvaluation(key, value)
            },
          },
        ],
      }}
      clientSideID={LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}
      context={{
        kind: 'user',
        key: user ? user.qid : 'anonymous',
        email: user?.email,
      }}
    >
      <ContextManager>{children}</ContextManager>
    </LDProvider>
  )
}
