import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { logErrorBoundaryError } from 'src/core/datadog-browser/logger'

export const CmsPanelErrorBoundary = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => null}
      onError={logErrorBoundaryError}
    >
      {children}
    </ErrorBoundary>
  )
}
