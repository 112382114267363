import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { DeprecatedFaqPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

import { renderRichText } from './RichText'

function FaqPanelComponent({ blok }: { blok: DeprecatedFaqPanelStoryblok }) {
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div className="md:gap-18 grid flex-col gap-8 py-8 md:grid-cols-3 md:py-12">
          <div className="flex flex-col gap-8 md:gap-4">
            <h2 className="text-4xl font-bold">{blok.heading}</h2>
            <div className="flex flex-col gap-1 text-gray-700">
              {renderRichText(blok.subheading)}
            </div>
          </div>

          <ul className="col-span-2 flex flex-col gap-8">
            {blok.faqs.map((faq) => {
              return (
                <React.Fragment key={faq._uid}>
                  <li>
                    <h3 className="mb-2 text-base font-bold">{faq.question}</h3>
                    <div className="flex flex-col text-gray-700">
                      {renderRichText(faq.answer)}
                    </div>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
      </CenteredContent>
    </div>
  )
}

export const FaqPanel = ({ blok }: { blok: DeprecatedFaqPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <FaqPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
