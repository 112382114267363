'use client'

import { cn } from '@qogita/ui/utils/cn'
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import Link from 'next/link'
import * as React from 'react'
import { ComponentProps } from 'react'
import { SetRequired } from 'type-fest'

export function NavigationMenu({
  className,
  children,
  ...props
}: ComponentProps<typeof NavigationMenuPrimitive.Root>) {
  return (
    <NavigationMenuPrimitive.Root
      className={cn(
        'relative flex max-w-max flex-1 items-center justify-center',
        className,
      )}
      {...props}
    >
      {children}
      <NavigationMenuViewport
        // These onPointer events are here to disable our the open on hover behavior
        // as suggested in https://github.com/radix-ui/primitives/issues/1630
        onPointerEnter={(event) => event.preventDefault()}
        onPointerMove={(event) => event.preventDefault()}
        onPointerLeave={(event) => event.preventDefault()}
      />
    </NavigationMenuPrimitive.Root>
  )
}

export function NavigationMenuList({
  className,
  ...props
}: ComponentProps<typeof NavigationMenuPrimitive.List>) {
  return (
    <NavigationMenuPrimitive.List
      className={cn(
        'group flex flex-1 list-none items-center justify-center space-x-1',
        className,
      )}
      {...props}
    />
  )
}

export const NavigationMenuItem = NavigationMenuPrimitive.Item

export function NavigationMenuTrigger({
  className,
  children,
  ...props
}: ComponentProps<typeof NavigationMenuPrimitive.Trigger>) {
  return (
    <NavigationMenuPrimitive.Trigger
      className={cn(
        'bg-background hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 group inline-flex h-9 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50',
        className,
      )}
      {...props}
    >
      {children}{' '}
    </NavigationMenuPrimitive.Trigger>
  )
}

export function NavigationMenuContent({
  className,
  ...props
}: ComponentProps<typeof NavigationMenuPrimitive.Content>) {
  return (
    <NavigationMenuPrimitive.Content
      className={cn('left-0 top-0 w-full', className)}
      {...props}
    />
  )
}

export function NavigationMenuLink({
  href,
  children,
  className,
  ...props
}: SetRequired<ComponentProps<typeof NavigationMenuPrimitive.Link>, 'href'>) {
  return (
    <NavigationMenuPrimitive.Link
      className={cn(
        'focus:outline-primary-700 inline-block w-full rounded p-3 hover:bg-gray-100 focus:outline focus:outline-2',
        className,
      )}
      asChild
      {...props}
    >
      <Link href={href}>{children}</Link>
    </NavigationMenuPrimitive.Link>
  )
}

function NavigationMenuViewport({
  className,
  ...props
}: ComponentProps<typeof NavigationMenuPrimitive.Viewport>) {
  return (
    <div className={cn('absolute left-0 right-0 top-full flex')}>
      <NavigationMenuPrimitive.Viewport
        className={cn(
          'origin-top-center bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out relative mx-6 mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border border-gray-300 bg-white data-[state=closed]:border-none',
          className,
        )}
        {...props}
      />
    </div>
  )
}
