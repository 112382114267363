import { cn } from '@qogita/ui/utils/cn'
import { ComponentProps } from 'react'

/**
 * Applies the appropriate padding and margin for application-width content in the center of the page
 * Use this inside something like a full-bleed background color to get the correct fit of the content
 */
export function CenteredContent({
  children,
  className,
  ...props
}: ComponentProps<'div'>) {
  return (
    <div
      className={cn(
        'max-w-site-content mx-auto w-full px-4 md:px-6',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
