import ky from 'ky'
import { logClientError } from 'src/core/datadog-browser/logger'
import { getCookie } from 'src/deprecated/lib/cookie'
import { isUserAgentBot } from 'src/lib/bot-detection'
import { TikTokEvent } from 'src/pages/api/tiktok/event'

import { hashString as hashUserData } from './Meta'

// We need to hash emails and phone numbers before sending them to ad attribution services
// This is because we don't want to send PII to third parties
// This function is taken almost verbatim from the MDN Web Crypto API documentation
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
async function hashString(input: string) {
  if (!window.crypto) throw new Error('Crypto API not available')

  const inputUint8 = new TextEncoder().encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', inputUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHexString = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')

  return hashHexString
}
export async function tikTokIdentifyUser({
  email,
  phoneNumber,
}: {
  email: string
  phoneNumber: string | null
}) {
  if (!window.ttq) return
  if (!window.crypto) return

  try {
    const hashedEmail = await hashString(email)
    const hashedPhoneNumber = phoneNumber ? await hashString(phoneNumber) : null

    const hashedUserDetails: {
      email: string
      phone_number?: string
    } = {
      email: hashedEmail,
    }

    if (hashedPhoneNumber) {
      hashedUserDetails['phone_number'] = hashedPhoneNumber
    }

    window.ttq.identify(hashedUserDetails)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logClientError(error as Error)
  }
}

// This overload is pretty huge, it's here to give us a type safe way to call the TikTok tracking
// function. It means that we can't call the function with an event that doesn't exist or with
// parameters that don't match the event.
// We can always move it to its own interface if it becomes irritating to work with
type ContentType = 'product' | 'product_group'
export function tikTokEvent(
  event: 'ViewContent',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency?: string
    event_id: string
  },
): void
export function tikTokEvent(
  event: 'Search',
  parameters: {
    query: string
    event_id: string
  },
): void
export function tikTokEvent(
  event: 'AddToCart',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency: string
    event_id: string
  },
): void
export function tikTokEvent(
  event: 'InitiateCheckout',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency: string
    event_id: string
  },
): void
export function tikTokEvent(
  event: 'PlaceAnOrder',
  parameters: {
    contents: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value: number
    currency: string
    event_id: string
  },
): void
export function tikTokEvent(
  event: 'CompleteRegistration',
  parameters?: { event_id?: string } | undefined,
): void
export function tikTokEvent(
  event: string,
  parameters?: {
    contents?: Array<{
      content_id: string
      content_name: string
      content_type: ContentType
    }>
    value?: number
    currency?: string
    order_id?: string
    query?: string
    event_id?: string
  },
) {
  if (!window.ttq) return

  try {
    window.ttq.track(event, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logClientError(error as Error)
  }
}

export function tikTokCustomEvent(
  event: string,
  parameters?: {
    contents?: Array<{
      content_id?: string
      content_name?: string
      content_type?: ContentType
    }>
    value?: number
    currency?: string
    order_id?: string
    status?: string
    event_id?: string
  },
) {
  if (!window.ttq) return

  try {
    window.ttq.track(event, parameters)
  } catch (error) {
    // We don't want any errors in our tracking to break the user experience
    // so we quietly log the error and move on
    logClientError(error as Error)
  }
}

type GetTikTokEventUserData = (userData: {
  email?: string
  phone?: string | null
}) => Promise<{
  email?: string
  phone?: string
  ttclid?: string
  user_agent: string
  ttp?: string
}>

const getTikTokEventUserData: GetTikTokEventUserData = async (userData) => {
  // TikTok Pixel automatically stores ClickID value in the _ttp browser cookie once available
  const cookie = getCookie(document.cookie, '_ttp')
  const ttclid = getCookie(document.cookie, '_ttclid')
  // Email addresses and phone numbers must be hashed before sending
  const userEmail = userData?.email
  const userPhone = userData?.phone

  const normalisedUserEmail = userEmail?.trim().toLowerCase()
  const normalisedUserPhone = userPhone?.trim()

  // Using the imported hashstring function from meta.tsx the one in this file
  // Is slightly different. Variations of this function are used in a few places
  // So Should probably check implementation for the tiktok pixel and standardise This
  const hashedUserEmail = await hashUserData(normalisedUserEmail)
  const hashedUserPhone = await hashUserData(normalisedUserPhone)

  return {
    user_agent: window.navigator.userAgent,
    ...(ttclid && { ttclid }),
    ...(hashedUserEmail && { email: hashedUserEmail }),
    ...(hashedUserPhone && { phone: hashedUserPhone }),
    ...(cookie && { ttp: cookie }),
  }
}

export async function tikTokEventApiEvent(data: TikTokEvent) {
  const isCrawler = isUserAgentBot(window.navigator.userAgent)
  if (isCrawler) return

  // Get hashed user data
  const tikTokEventUserData = await getTikTokEventUserData(data.user)

  // Add default values for required fields
  const eventData = {
    ...data,
    user: tikTokEventUserData,
    page: {
      url: window.location.href,
      referrer: document.referrer,
    },
  }

  return ky
    .post('/api/tiktok/event/', {
      json: eventData,
    })
    .catch((error) => {
      logClientError(error as Error)
    })
}
