import { User } from '@qogita/canary-types'
// import { DisallowedUserCurrencyError } from 'src/deprecated/lib/error'
import type { Currency, CurrencySymbolsMap } from 'src/deprecated/types/index'

import { DisallowedUserCurrencyError } from './custom-errors'

type GetCurrencySymbol = (code: string) => string
type GetIntlNumberFormatForCurrency = (
  locale: string,
  currency: keyof CurrencySymbolsMap,
  exponent?: number,
) => Intl.NumberFormat
type GetFormattedCurrencyWithSymbol = (
  value: number,
  locale: string,
  currency: keyof CurrencySymbolsMap,
  exponent?: number,
) => string

/**
 * Tests whether the user's browser supports 'narrowSymbol' currency display.
 * Very old versions of safari do not support this.
 */
function isNarrowSymbolSupported() {
  try {
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'narrowSymbol',
    })
    formatter.format(1)
    return true
  } catch {
    // We assume the reason this fails is the browser does not support narrowSymbol.
    return false
  }
}

export const CURRENCY_SYMBOLS_MAP = {
  EUR: '€',
  GBP: '£',
  USD: '$',
}

export const getCurrencySymbol: GetCurrencySymbol = (code) => {
  return CURRENCY_SYMBOLS_MAP[code as keyof CurrencySymbolsMap] ?? ''
}

/**
 * @param locale A locale.
 * @param currency A currency.
 * @param exponent The exponent. Default: 2.
 * @returns An instance of Intl.NumberFormat configured for currency.
 */
const getIntlNumberFormatForCurrency: GetIntlNumberFormatForCurrency = (
  locale,
  currency,
  exponent = 2,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    signDisplay: 'auto',
    currencyDisplay: isNarrowSymbolSupported() ? 'narrowSymbol' : 'symbol',
    currencySign: 'standard',
    minimumIntegerDigits: 1,
    minimumFractionDigits: exponent,
    maximumFractionDigits: exponent,
  })
}

/**
 * @deprecated Use `Money` component instead.
 * @param value The amount to be formatted.
 * @param locale A locale.
 * @param currency A currency.
 * @param exponent The exponent. Default: 2.
 * @returns A locale-sensitive currency string of a given number.
 */
export const getFormattedCurrencyWithSymbol: GetFormattedCurrencyWithSymbol = (
  value,
  locale,
  currency,
  exponent = 2,
) => {
  return getIntlNumberFormatForCurrency(locale, currency, exponent).format(
    value,
  )
}

export type CurrencyFormatterOptions = Omit<
  Intl.NumberFormatOptions,
  'currency' | 'style' | 'currencyDisplay' | 'currencySign'
>
/**
 * Formats a number into standard currency accounting format.
 * Locale is fixed to en-GB to avoid hydration errors when server rendering if the client is
 * in a different locale to our server.
 * @param currency ISO 3 character currency code
 * @returns a formatter to format numbers into standard currency accounting format
 */
export const getCurrencyFormatter =
  (currency: Currency, options: CurrencyFormatterOptions = {}) =>
  (value: number | string) =>
    new Intl.NumberFormat('en-GB', {
      currency,
      style: 'currency',
      currencyDisplay: isNarrowSymbolSupported() ? 'narrowSymbol' : 'symbol',
      currencySign: 'accounting',
      ...options,
    }).format(Number(value))

export const isDisallowedCurrency = (currency: User['currency']) => {
  return ['PLN', 'RON'].includes(currency)
}

export const getValidatedUserCurrency = (currency: User['currency']) => {
  if (currency === 'PLN' || currency === 'RON') {
    throw new DisallowedUserCurrencyError('The user currency is not allowed.', {
      currency,
    })
  }

  return currency
}
