import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'

import { ENV_TAG } from '../constants'
import {
  DATADOG_ANONYMOUS_SESSION_REPLAY_SAMPLE_RATE,
  DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_SERVICE_NAME,
  DATADOG_SITE,
  IS_DATADOG_RUM_ENABLED,
} from './constants'
import { createClientDebugLogger } from './logger'

// enable/disable
// localStorage.setItem("__rum_debug_logger", "true")
// localStorage.removeItem("__rum_debug_logger")
const debug = createClientDebugLogger('__rum_debug_logger')

export function initDatadogRum({
  isAuthenticated,
  isDatadogAccepted,
}: {
  isAuthenticated: boolean
  isDatadogAccepted: boolean
}) {
  if (!IS_DATADOG_RUM_ENABLED) return

  // we allow recording for authenticated users and some anonymous sessions
  const isRecordingAllowed =
    isAuthenticated || sessionReplay.isAnonymousSessionSampled

  const config = datadogRum.getInitConfiguration()
  const trackingConsent = isDatadogAccepted ? 'granted' : 'not-granted'

  // beforeSend must be defined outside the init config
  // to ensure it always executes with the latest data
  const beforeSend: RumInitConfiguration['beforeSend'] = (event) => {
    if (event.type === 'view') {
      // view events can't be dismissed
      return true
    }
    return isRecordingAllowed
  }

  if (config === undefined) {
    datadogRum.init({
      site: DATADOG_SITE,
      service: DATADOG_RUM_SERVICE_NAME,
      applicationId: DATADOG_RUM_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '',
      env: ENV_TAG,
      trackingConsent,
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      startSessionReplayRecordingManually: true,
      trackResources: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        `${window.location.protocol}//${window.location.host}`,
        'https://api.qogita.com',
        'https://api.test.qogita.com',
      ],
      enableExperimentalFeatures: ['feature_flags'],
      beforeSend,
    })
  }

  datadogRum.setTrackingConsent(trackingConsent)

  debug(`authentication status: ${isAuthenticated}`)

  if (!sessionReplay.isRecording && isRecordingAllowed) {
    sessionReplay.startSessionReplayRecording()
  }

  if (sessionReplay.isRecording && !isRecordingAllowed) {
    sessionReplay.stopSessionReplayRecording()
  }
}

class SessionReplay {
  public isRecording: boolean
  public isAnonymousSessionSampled: boolean

  constructor() {
    this.isRecording = false
    this.isAnonymousSessionSampled = this.getAnonymousSessionReplaySample()
    debug('initialized')
  }

  private getAnonymousSessionReplaySample() {
    // Compute the sample rate
    const sampled =
      Math.random() * 100 < DATADOG_ANONYMOUS_SESSION_REPLAY_SAMPLE_RATE
    debug(`anonymous session replay sampled: ${sampled}`)
    return sampled
  }

  startSessionReplayRecording() {
    debug(`start recording: ${datadogRum.getSessionReplayLink()}`)
    this.isRecording = true
    datadogRum.startSessionReplayRecording()
  }

  stopSessionReplayRecording() {
    debug('stop recording')
    this.isRecording = false
    datadogRum.stopSessionReplayRecording()
  }

  startAnonymousSessionReplayRecording() {
    debug('force anonymous recording')
    this.isAnonymousSessionSampled = true
    if (!this.isRecording) {
      this.startSessionReplayRecording()
    }
  }

  refreshAnonymousSessionReplayRecording() {
    debug('refresh anonymous recording')
    this.isAnonymousSessionSampled = this.getAnonymousSessionReplaySample()
    if (!this.isAnonymousSessionSampled) {
      this.stopSessionReplayRecording()
    }
  }
}

export const sessionReplay = new SessionReplay()
