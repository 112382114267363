import { PresignedPost } from '@aws-sdk/s3-presigned-post'
import {
  MutationState,
  useMutation,
  useMutationState,
} from '@tanstack/react-query'
import ky, { HTTPError } from 'ky'
import { logClientError } from 'src/core/datadog-browser/logger'

export type UploadFile = {
  destination: string
  binaryFile: File
}

class FileUploadError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public filename: string,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'FileUploadError'
  }
}

export const uploadFileToStorage = async (file: UploadFile) => {
  const { destination, binaryFile } = file
  const filename = binaryFile.name
  const { url, fields } = await ky
    .get<PresignedPost>(
      `/api/presignUrl/?file=${filename}&destination=${destination}`,
    )
    .json()

  const formData = new FormData()

  Object.entries({ ...fields, file: binaryFile }).forEach(([key, value]) => {
    formData.append(key, value as string | Blob)
  })

  await ky
    .extend({
      hooks: {
        beforeError: [
          (error) => {
            const fileUploadError = new FileUploadError(error, filename)
            logClientError(fileUploadError, {
              message: 'AWS File upload error',
            })
            return fileUploadError
          },
        ],
      },
    })
    .post(url, {
      timeout: false, // let AWS control the timeout
      body: formData,
      headers: {
        'Content-Length': binaryFile.size.toString(),
      },
    })

  return destination
}

const mutationKey = ['uploadFileToStorage']
type MutationData = {
  url: string
  id: string
}
type MutationVariables = {
  file: File
  id: string
  destination: string
}
export function useUploadFileToStorageMutation() {
  return useMutation({
    mutationKey,
    mutationFn: async ({
      file,
      id,
      destination,
    }: MutationVariables): Promise<MutationData> => {
      const url = await uploadFileToStorage({
        binaryFile: file,
        destination,
      })
      return { url, id }
    },
  })
}

/** Hook used to get state information about all uploadFileToStorage mutations */
export function useUploadFileToStorageMutationState() {
  return useMutationState<
    MutationState<MutationData, unknown, MutationVariables>
  >({
    filters: {
      mutationKey,
    },
  })
}
