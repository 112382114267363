import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { User } from '@qogita/canary-types'
import { use, useEffect } from 'react'

type DatadogUser = Pick<
  User,
  'qid' | 'email' | 'account' | 'pricingModel' | 'createdAt'
>

/**
 * Good to know: datadog setUser can be called before
 * SDK initialization, so there's no need to wait until after init.
 */
export function setDatadogUser({ user }: { user: DatadogUser | null }) {
  if (user) {
    const newUser = {
      id: user.qid,
      email: user.email,
      name: user.account,
      pricingModel: user.pricingModel,
      createdAt: user.createdAt,
    }
    datadogLogs.setUser(newUser)
    datadogRum.setUser(newUser)
  } else {
    datadogLogs.clearUser()
    datadogRum.clearUser()
  }
}

export function useIdentifyDatadogUser({
  userPromise,
}: {
  userPromise: Promise<User | null>
}) {
  const user = use(userPromise)

  useEffect(() => {
    if (user?.qid) {
      setDatadogUser({
        user: {
          qid: user.qid,
          email: user.email,
          pricingModel: user.pricingModel,
          account: user.account,
          createdAt: user.createdAt,
        },
      })
    } else {
      setDatadogUser({ user: null })
    }
  }, [
    user?.qid,
    user?.email,
    user?.pricingModel,
    user?.account,
    user?.createdAt,
  ])

  return null
}
