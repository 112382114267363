import { logClientError } from 'src/core/datadog-browser/logger'

export const bingEvent = (
  eventName: string,
  eventParams?: Record<string, unknown>,
) => {
  if (!window.uetq) return

  try {
    window.uetq.push('event', eventName, eventParams ?? {})
  } catch (error) {
    logClientError(error as Error)
  }
}
