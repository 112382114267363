import { cn } from '@qogita/ui/utils/cn'
import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'
import { DeprecatedPeoplePanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'
import { storyblokImageLoader } from 'src/deprecated/components/cms/storyblokImageLoader'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

function PeoplePanelComponent({
  blok,
}: {
  blok: DeprecatedPeoplePanelStoryblok
}) {
  const peopleCount = blok.people.length
  return (
    <div {...storyblokEditable(blok)} className="bg-gray-50">
      <CenteredContent>
        <div className="flex flex-col items-center gap-8 py-8 md:py-12">
          <h2 className="max-w-2xl text-balance text-4xl font-bold md:text-center">
            {blok.heading}
          </h2>
          {blok.description ? (
            <p className="max-w-2xl text-pretty text-lg font-light md:text-center">
              {blok.description}
            </p>
          ) : null}
          <div
            className={cn('grid grid-cols-1 gap-8 self-stretch md:gap-6', {
              'md:grid-cols-2': peopleCount === 2,
              'md:grid-cols-3': peopleCount === 3,
              'md:grid-cols-2 lg:grid-cols-4': peopleCount === 4,
            })}
          >
            {blok.people.map((person) => {
              return (
                <div
                  key={person._uid}
                  {...storyblokEditable(person)}
                  className="flex flex-col"
                >
                  {/* The h-[80vw] was used to address mobile breakpoints where the content had excessive width and insufficient height, particularly at the upper end of the mobile breakpoint range.*/}
                  <div className="relative mb-4 h-[80vw] w-full md:h-96 lg:h-64">
                    <Image
                      loader={storyblokImageLoader}
                      src={person.image.filename}
                      alt={person.image.alt || ''}
                      fill
                      className="rounded-lg object-cover md:max-w-full"
                    />
                  </div>
                  <h3 className="text-lg font-bold">{person.name}</h3>
                  <div className="space-y-2 text-gray-700">
                    {person.jobTitle}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}

export const PeoplePanel = ({
  blok,
}: {
  blok: DeprecatedPeoplePanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <PeoplePanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
