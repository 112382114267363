import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { DeprecatedBannerPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'

import { Anchor } from './Anchor'

const BannerPanelComponent = ({
  blok,
}: {
  blok: DeprecatedBannerPanelStoryblok
}) => {
  const { isAuthenticated } = useAuthentication()
  const title =
    isAuthenticated && blok.authenticatedTitle
      ? blok.authenticatedTitle
      : blok.anonymousTitle
  const description =
    isAuthenticated && blok.authenticatedDescription
      ? blok.authenticatedDescription
      : blok.anonymousDescription
  const [cta] =
    isAuthenticated && blok.authenticatedCta
      ? blok.authenticatedCta
      : blok.anonymousCta

  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex justify-center px-4 py-24"
    >
      <div className="flex max-w-5xl flex-col items-center justify-center gap-6 text-balance text-center text-white">
        <h2 className="text-4xl font-bold">{title}</h2>
        {description ? (
          <p className="max-w-2xl text-3xl text-gray-200">{description}</p>
        ) : null}
        {cta?.component === 'deprecatedCta' ? (
          <Anchor
            blok={cta}
            className="w-full md:w-auto"
            color="primaryInverse"
            appearance="contained"
            size="medium"
          />
        ) : null}
      </div>
    </div>
  )
}

export const BannerPanel = ({
  blok,
}: {
  blok: DeprecatedBannerPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <BannerPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
