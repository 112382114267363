import { logClientError } from 'src/core/datadog-browser/logger'

export function getUrlPathname(url?: string) {
  // We shouldn't ever be at a point where url is undefined.
  // If our variants don't have images then canary will return an array with a
  // single element in it that points to our fallback image.
  if (!url) {
    logClientError(new Error('url was undefined'), {
      functionArgs: { url },
    })
    return ''
  }

  try {
    const pathname = new URL(url).pathname
    return pathname
  } catch (error) {
    logClientError(error as Error, {
      functionArgs: { url },
    })
    return ''
  }
}
