import {
  CanaryClient,
  GetOrderClaimsSearchParams,
  GetOrderLinesSearchParams,
  GetOrderSalesSearchParams,
  GetOrdersSearchParams,
} from '@qogita/canary-client'
import { useCanaryClient } from '@qogita/canary-client/provider'
import { BuyerOrderStatus } from '@qogita/canary-types'
import {
  infiniteQueryOptions,
  queryOptions,
  skipToken,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { logClientError } from 'src/core/datadog-browser/logger'

import { getCartQueries } from './cart-queries'
import { getUserQueries } from './user-queries'
import { replaceUndefinedValuesWithNull } from './utilities'

export function getOrderQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['orders'] as const,
    allLists: () => [...queries.all(), 'list'] as const,
    list: (params: GetOrdersSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLists(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrders(params),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    allDetails: () => [...queries.all(), 'detail'] as const,
    detail: (qid: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid] as const,
        queryFn: () => canaryClient.getOrder(qid),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    detailOptionalQid: (qid?: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid ?? null] as const,
        queryFn: qid ? () => canaryClient.getOrder(qid) : skipToken,
      }),
    allSales: (qid: string) =>
      [...queries.detail(qid).queryKey, 'sales'] as const,
    sales: (qid: string, params: GetOrderSalesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allSales(qid),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderSales(qid, params),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    salesInfiniteList: (
      qid: string,
      params: Omit<GetOrderSalesSearchParams, 'page'> = {},
    ) =>
      infiniteQueryOptions({
        queryKey: [...queries.sales(qid, params).queryKey, 'infinite'],
        queryFn: ({ pageParam }) =>
          canaryClient.getOrderSales(qid, { ...params, page: pageParam }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          if (!lastPage.next) return null
          try {
            // Canary gives us the full next url, we don't care about that, but we can parse the page param from it
            const nextPageCanaryUrl = new URL(lastPage.next)
            const nextPageParameter = Number(
              nextPageCanaryUrl.searchParams.get('page'),
            )
            if (isNaN(nextPageParameter)) {
              return null
            }
            return nextPageParameter
          } catch (error) {
            logClientError(error as Error, {
              message: 'Failed to parse next page param from Canary URL',
            })
            return null
          }
        },
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    allLines: (qid: string) =>
      [...queries.detail(qid).queryKey, 'lines'] as const,
    lines: (qid: string, params: GetOrderLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(qid),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderLines(qid, params),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
    allClaims: (qid: string) =>
      [...queries.detail(qid).queryKey, 'claims'] as const,
    claims: (qid: string, params: GetOrderClaimsSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allClaims(qid),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderClaims(qid, params),
        staleTime: 1000 * 60 * 60, // 1 hour
      }),
  }

  return queries
}

export const BUYER_ORDER_STATUS_OPTIONS = [
  'CHECKOUT',
  'FINANCED',
  'AWAITING_PAYMENT',
  'PAID_PREPAID',
  'IN_PROGRESS',
  'SHIPPED',
  'PAID_FINANCED',
  'EXPIRED',
  'CANCELLED',
  'PAYMENT_OVERDUE',
  'DEFAULTED',
  'AWAITING_MONDU_CHECKOUT',
  'AWAITING_MONDU_DECISION',
  'MONDU_DECLINED',
] as const satisfies ReadonlyArray<BuyerOrderStatus>
export type GetOrdersSortOption = NonNullable<GetOrdersSearchParams['order']>
type RemoveMinuses<T extends string> = T extends `-${infer U}` ? U : T
export type GetOrdersSortField = RemoveMinuses<GetOrdersSortOption>
export const GET_ORDERS_SORT_OPTIONS = [
  'fid',
  '-fid',
  'total',
  '-total',
  'submitted_at',
  '-submitted_at',
] as const satisfies ReadonlyArray<GetOrdersSortOption>

export function useReorderMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const userQueries = getUserQueries(canaryClient)
  const cartQueries = getCartQueries(canaryClient)

  return useMutation({
    mutationFn: () => canaryClient.reorder(qid),
    onSuccess: async (newCart) => {
      // The new cart becomes the user's active cart, so we can update a few cache entries
      // automatically to avoid waiting on all the refetches.
      queryClient.setQueryData(
        userQueries.detail().queryKey,
        (previousUser) => {
          if (!previousUser) return
          return { ...previousUser, activeCartQid: newCart.qid }
        },
      )
      queryClient.setQueryData(cartQueries.activeDetail().queryKey, newCart)

      await queryClient.invalidateQueries()
    },
  })
}
