import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { useAuthentication } from 'src/core/authentication/provider'
import { DeprecatedHeroPanelStoryblok } from 'src/core/cms/storyblok-component-types'
import { CenteredContent } from 'src/deprecated/components/Marketing/CenteredContent'

import { Anchor } from './Anchor'

export function HeroPanel({ blok }: { blok: DeprecatedHeroPanelStoryblok }) {
  const { isAuthenticated } = useAuthentication()
  const [cta] =
    isAuthenticated && blok.authenticatedCta
      ? blok.authenticatedCta
      : blok.anonymousCta
  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex flex-col items-center text-white"
    >
      <CenteredContent>
        <div className="flex flex-col-reverse gap-4 py-12 md:grid md:grid-cols-2 md:items-center md:gap-6 md:py-14">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <h1 className="text-balance text-5xl font-bold">
                {blok.heading}
              </h1>
              <p className="text-pretty text-base text-gray-100">
                {blok.description}
              </p>
            </div>
            {cta ? (
              <Anchor
                blok={cta}
                color="primaryInverse"
                appearance="contained"
                size="medium"
                className="md:self-start"
              />
            ) : null}
          </div>
          <div>
            {blok.image.map((imageBlok) => (
              <StoryblokComponent blok={imageBlok} key={imageBlok?._uid} />
            ))}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}
