'use client'

import { useEffect } from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { useConsent } from 'src/core/consent'
import { initDatadogBrowserLogger } from 'src/core/datadog-browser/logger'
import { initDatadogRum } from 'src/core/datadog-browser/rum'

export function DatadogInit() {
  const { hasConsent } = useConsent()
  const { isAuthenticated } = useAuthentication()

  const isDatadogAccepted = hasConsent('performance')

  useEffect(() => {
    initDatadogRum({ isAuthenticated, isDatadogAccepted })
  }, [isAuthenticated, isDatadogAccepted])

  useEffect(() => {
    initDatadogBrowserLogger({ isDatadogAccepted })
  }, [isDatadogAccepted])

  return null
}
