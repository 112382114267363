import { datadogRum } from '@datadog/browser-rum'
import { GetVariantsSearchSearchParams } from '@qogita/canary-client'
import {
  AllocationLineAllocationStatus,
  AnnualTurnover,
  BuyerCurrency,
  Checkout,
  NumberOfEmployees,
  OrderRetrieve,
  PersonaDistributionChannels,
  User,
  Variant,
  VariantOffersSearch,
  VariantSearch,
  VariantWeb,
  WatchlistItem,
} from '@qogita/canary-types'
import { useEffect, useMemo } from 'react'
import { logClientError } from 'src/core/datadog-browser/logger'
import { useUser } from 'src/deprecated/api/user-queries'
import { isNewPricingModelVariantSearch } from 'src/deprecated/api/variant-queries'
import { useNewTaxonomyEnabled } from 'src/deprecated/hooks/featureFlags'
import { usePrevious } from 'src/deprecated/hooks/usePrevious'
import {
  getFormattedCurrencyWithSymbol,
  getValidatedUserCurrency,
} from 'src/deprecated/lib/currency'
import { isDeepEqual } from 'src/deprecated/lib/isDeepEqual'
import { getUrlPathname } from 'src/deprecated/lib/url'

import { track, useAnalytics } from './AnalyticsProvider'
import { bingEvent } from './Bing'
import { gtagConversion, gtagEvent } from './Gtag'
import { metaConversionEvent, metaCustomEvent, metaEvent } from './Meta'
import { tapfiliateConversion, tapfiliateSignupUser } from './Tapfiliate'
import { tikTokCustomEvent, tikTokEvent, tikTokEventApiEvent } from './TikTok'

const LINKED_IN_CONVERSION_IDS = {
  UserSignedUp: 13500594,
  UserSignedUpQualified: 15448916,
  ProductAdded: 13500602,
  CheckoutCompleted: 13500786,
  FirstCheckoutStarted: 13969194,
  FirstCheckoutCompleted: 13969162,
}

const GOOGLE_CONVERSION_IDS = {
  UserSignedUp: 'PTdeCLrIqYwYEM65vKsC',
  UserSignedUpQualified: '2r34CJ2szY0ZEM65vKsC',
  UserVerificationStatusChanged: 'Ze9PCNO325cYEM65vKsC',
  ProductAdded: 'QttuCNzHm40YEM65vKsC',
  CheckoutStarted: 'XCrnCKiJm40YEM65vKsC',
  CheckoutCompleted: 'qy8iCLadqYwYEM65vKsC',
  FirstCheckoutStarted: 'WpBmCNzBm9gYEM65vKsC',
  FirstCheckoutCompleted: 'zK3vCKHY1p4YEM65vKsC',
}

export type EventOrigin =
  | 'catalog'
  | 'brandpage'
  | 'recommendations'
  | 'watchlist'
  | 'deal'
  | 'PDP'
  | 'cart'
  | 'checkout'
  | 'claim'

const trackLinkedInConversion = (
  type: keyof typeof LINKED_IN_CONVERSION_IDS,
) => {
  if (!window.lintrk) {
    return
  }

  const conversionId = LINKED_IN_CONVERSION_IDS[type]
  try {
    window.lintrk('track', { conversion_id: conversionId })
  } catch (error) {
    logClientError(error as Error)
  }
}

const AnalyticsEvents = {
  AccountManagerContactClicked: 'Account Manager Contact Clicked',
  AccountMenuOpened: 'Account Menu Opened',
  ButtonClicked: 'Button Clicked',
  CartExpiryAlertViewed: 'Cart Expiry Alert Viewed',
  CartMovRecommendationsLinkClicked: 'Cart Mov Recommendations Link Clicked',
  CartViewed: 'Cart Viewed',
  CheckoutCompleted: 'Checkout Completed',
  CheckoutStarted: 'Checkout Started',
  CheckoutStepCompleted: 'Checkout Step Completed',
  FirstCheckoutCompleted: 'First Checkout Completed',
  FirstCheckoutStarted: 'First Checkout Started',
  ManualSourcingLinkClicked: 'Manual Sourcing Link Clicked',
  NavigationMenuOpened: 'Navigation Menu Opened',
  CheckoutHasChangesAlertViewed: 'Checkout Has Changes Alert Viewed',
  ProductAdded: 'Product Added',
  ProductClicked: 'Product Clicked',
  ProductListEmptyStateViewed: 'Product List Empty State Viewed',
  ProductListFiltered: 'Product List Filtered',
  ProductListViewed: 'Product List Viewed',
  ProductsSearched: 'Products Searched',
  ProductViewed: 'Product Viewed',
  ProductWatchlisted: 'Product Watchlisted',
  RegistrationStepCompleted: 'Registration Step Completed',
  SellViaQogitaLinkClicked: 'Sell Via Qogita Link Clicked',
  ShippingBannerModalViewed: 'Shipping Banner Modal Viewed',
  ProductsShowOnlyWatchlistedToggled: 'Products Show Only Watchlisted Toggled',
  TargetPriceAdded: 'Target Price Added',
  TargetPriceEdited: 'Target Price Edited',
  TargetQuantityAdded: 'Target Quantity Added',
  TargetQuantityEdited: 'Target Quantity Edited',
  UserSignedIn: 'User Signed In',
  UserSignedOut: 'User Signed Out',
  UserSignedUp: 'User Signed Up',
  UserSignedUpQualified: 'User Signed Up Qualified',
  UserVerificationStatusChanged: 'User Verification Status Changed',
  WatchlistDownloaded: 'Watchlist Downloaded',
  WatchlistUploaded: 'Watchlist Uploaded',
  WhyAmIBeingChargedVatLinkClicked: 'Why Am I Being Charged VAT Link Clicked',
}

type TrackedProduct = {
  productId: string
  sku: string
  category?: string
  name: string
  brand?: string
  price?: string
  minPrice?: string
  currency?: string | null
  minPriceCurrency?: string | null
  quantity?: number
  imageUrl: string
  url: string
  hasDeals?: boolean
  position?: number
  objectID: string
  isNewPricingModelVariantSearch?: boolean
}

const getProductDetailsFromSearchHit = ({
  searchHit,
}: {
  searchHit: VariantSearch | VariantOffersSearch
}): TrackedProduct => {
  if (isNewPricingModelVariantSearch(searchHit)) {
    return {
      productId: searchHit.gtin,
      sku: searchHit.gtin,
      category: searchHit.categoryName,
      name: searchHit.name,
      brand: searchHit.brandName,
      minPrice: searchHit.minPrice || undefined,
      minPriceCurrency: searchHit.minPriceCurrency || undefined,
      imageUrl: getUrlPathname(searchHit.imageUrl),
      url: `/products/${searchHit.fid}/${searchHit.slug}`,
      objectID: searchHit.gtin,
      isNewPricingModelVariantSearch: true,
    }
  }

  return {
    productId: searchHit.gtin,
    sku: searchHit.gtin,
    category: searchHit.categoryName,
    name: searchHit.name,
    brand: searchHit.brandName,
    price: searchHit.price || undefined,
    currency: searchHit.priceCurrency || undefined,
    imageUrl: getUrlPathname(searchHit.imageUrl),
    url: `/products/${searchHit.fid}/${searchHit.slug}`,
    objectID: searchHit.gtin,
    hasDeals: Boolean(searchHit.deals),
    position: searchHit.position,
    isNewPricingModelVariantSearch: false,
  }
}

const getProductDetailsFromVariant = ({
  variant,
}: {
  variant: Pick<
    Variant,
    | 'gtin'
    | 'name'
    | 'category'
    | 'brand'
    | 'price'
    | 'priceCurrency'
    | 'images'
    | 'fid'
    | 'slug'
  >
}): TrackedProduct => {
  return {
    productId: variant.gtin,
    sku: variant.gtin,
    category: variant.category.name,
    name: variant.name,
    brand: variant.brand.name,
    price: variant.price || undefined,
    currency: variant.priceCurrency,
    imageUrl: getUrlPathname(variant.images[0]?.url),
    url: `/products/${variant.fid}/${variant.slug}`,
    objectID: variant.gtin,
  }
}

const getProductDetailsFromVariantWeb = ({
  variant,
}: {
  variant: Pick<
    VariantWeb,
    | 'gtin'
    | 'name'
    | 'categoryTree'
    | 'brand'
    | 'price'
    | 'priceCurrency'
    | 'images'
    | 'fid'
    | 'slug'
  >
}): TrackedProduct => {
  return {
    productId: variant.gtin,
    sku: variant.gtin,
    category: variant.categoryTree.at(-1)?.name,
    name: variant.name,
    brand: variant.brand.name,
    price: variant.price || undefined,
    currency: variant.priceCurrency,
    imageUrl: getUrlPathname(variant.images[0]?.url),
    url: `/products/${variant.fid}/${variant.slug}`,
    objectID: variant.gtin,
  }
}

type TrackSearchSuggestionClickedMetadata = {
  query: string
  suggestion: {
    type: 'query' | 'product' | 'brand' | 'category'
    name?: string
    gtin?: string
  }
  userShowOnlyTraceableStock: boolean
}

type TrackSuggestionsSearchedMetadata = {
  searchTerm: string
}

type TrackProductAddedMetadata = {
  product: {
    price: string
    currency: string
    name: string
    brand: string
    category: string
    productId: string
  }
  cartId: string
  quantity: number
}

type TrackProductClickedMetadata = {
  product: VariantSearch | VariantOffersSearch
  searchHash?: string
}

type TrackProductClickedFromWatchlistMetadata = {
  product: WatchlistItem
}

type ProductListViewedMetadata = {
  products: VariantSearch[] | VariantOffersSearch[]
  category?: string
}

type ProductListFilteredMetadata = {
  filters: {
    type: string
    value: string | string[] | boolean | undefined
  }[]
  products: VariantSearch[] | VariantOffersSearch[]
  searchHash?: string
  taxonomy: 'v1' | 'v2'
}

type TrackCheckoutStartedMetadata = {
  checkout: Pick<
    Checkout,
    | 'cartQid'
    | 'currency'
    | 'subtotal'
    | 'shipping'
    | 'qid'
    | 'isDeal'
    | 'pricingModel'
  >
}

type TrackFirstCheckoutStartedMetadata = {
  checkout: Pick<
    Checkout,
    'cartQid' | 'currency' | 'subtotal' | 'shipping' | 'qid' | 'isDeal'
  >
}

type TrackCheckoutStepCompleted = {
  step: 'address' | 'paymentMethod'
  checkout: Pick<Checkout, 'cartQid' | 'qid' | 'isDeal' | 'pricingModel'>
}

type TrackCheckoutCompletedMetadata = {
  order: OrderRetrieve
}

type TrackFirstCheckoutCompletedMetadata = {
  order: OrderRetrieve
  email?: string
}

type TrackUserSignedInMetadata = {
  email: string
}

type RegistrationStepCompletedMetadata = {
  step: 'Basic Details' | 'Company Details' | 'Distribution Channels'
}

type TrackUserSignedUpMetadata = {
  email: string
  account: string
  company: string
  personaDistributionChannels?: PersonaDistributionChannels[] | null
  annualTurnover?: AnnualTurnover
  numberOfEmployees?: NumberOfEmployees
}

type TrackUserVerificationStatusChangedMetadata = {
  status: 'email verified' | 'phone verified' | 'all verified'
  userId: string | undefined
  verification: {
    email: boolean | undefined
    phone: boolean | undefined
  }
}

type TrackNavigationMenuOpened = {
  menuType: string
  taxonomy: 'v1' | 'v2'
}

type TrackAccountManagerContactClickedMetadata = {
  medium: string
}

type TrackProductWatchlistedMetadata = {
  product: VariantWeb | VariantSearch | VariantOffersSearch
}

type TrackWhyAmIBeingChargedVatLinkClicked = {
  checkout: Checkout
}

export function useTrackEvent() {
  const { track } = useAnalytics()
  const { data: user } = useUser()

  return useMemo(() => {
    const tracking = {
      trackSearchSuggestionClicked({
        query,
        suggestion,
        userShowOnlyTraceableStock,
      }: TrackSearchSuggestionClickedMetadata) {
        track(AnalyticsEvents.ProductsSearched, {
          query,
          suggestion,
          userShowOnlyTraceableStock,
        })
        tikTokCustomEvent('Search', {
          contents: [
            {
              content_id: suggestion.gtin,
              content_name: suggestion.name,
              content_type: 'product',
            },
          ],
          event_id: query,
        })
        tikTokEventApiEvent({
          event: 'Search',
          event_id: query,
          properties: {
            contents: [
              {
                content_id: suggestion.gtin,
                content_name: suggestion.name,
              },
            ],
          },
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
      },
      trackSuggestionsSearched({
        searchTerm,
      }: TrackSuggestionsSearchedMetadata) {
        metaConversionEvent({
          event_id: searchTerm,
          event_name: 'Search',
          custom_data: {
            search_string: searchTerm,
          },
          original_event_data: {
            event_name: 'Search',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaEvent(
          'Search',
          {
            search_string: searchTerm,
          },
          {
            eventID: searchTerm,
          },
        )
        tikTokEvent('Search', { query: searchTerm, event_id: searchTerm })
        tikTokEventApiEvent({
          event: 'Search',
          event_id: searchTerm,
          properties: {
            search_string: searchTerm,
          },
          user: {
            email: user?.email,
          },
        })
        gtagEvent('search', { search_term: searchTerm })
      },
      trackProductAdded({
        product,
        cartId,
        quantity,
      }: TrackProductAddedMetadata) {
        try {
          const value = Number(product.price) * Number(quantity)

          tikTokEvent('AddToCart', {
            contents: [
              {
                content_id: product.productId,
                content_name: product.name,
                content_type: 'product',
              },
            ],
            value,
            currency: product.currency,
            event_id: product.productId,
          })
          tikTokEventApiEvent({
            event: 'AddToCart',
            event_id: product.productId,
            properties: {
              value,
              currency: product.currency,
              content_ids: [product.productId],
              contents: [
                {
                  content_id: product.productId,
                  content_name: product.name,
                  content_category: product.category,
                  brand: product.brand,
                  price: Number(product.price),
                },
              ],
              num_items: quantity,
              content_type: 'product',
            },
            user: {
              email: user?.email,
              phone: user?.phone,
            },
          })
          trackLinkedInConversion('ProductAdded')

          bingEvent(AnalyticsEvents.ProductAdded)

          metaConversionEvent({
            event_id: product.productId,
            event_name: 'AddToCart',
            custom_data: {
              value,
              currency: product.currency ?? undefined,
              content_ids: [product.productId],
              content_name: product.name,
              content_type: 'product',
            },
            original_event_data: {
              event_name: 'AddToCart',
            },
            user_data: {
              email: user?.email,
              phone: user?.phone,
            },
          })

          metaEvent(
            'AddToCart',
            {
              value,
              currency: product.currency ?? undefined,
              content_ids: [product.productId],
              content_name: product.name,
              content_type: 'product',
            },
            {
              eventID: product.productId,
            },
          )

          gtagEvent('add_to_cart', {
            items: [
              {
                item_id: product.productId,
                item_name: product.name,
                item_brand: product.brand,
                item_category: product.category,
                price: product.price,
                quantity,
              },
            ],
            value,
            currency: product.currency,
          })
          gtagConversion(GOOGLE_CONVERSION_IDS.ProductAdded, {
            currency: product.currency ?? undefined,
            value,
            transaction_id: cartId,
          })
        } catch (error) {
          logClientError(error as Error)
        }
      },
      trackProductClicked({
        product,
        searchHash,
      }: TrackProductClickedMetadata) {
        track(AnalyticsEvents.ProductClicked, {
          eventType: 'click',
          ...getProductDetailsFromSearchHit({
            searchHit: product,
          }),
          origin: 'catalog',
          searchHash,
        })
      },
      trackProductClickedFromWatchlist({
        product,
      }: TrackProductClickedFromWatchlistMetadata) {
        track(AnalyticsEvents.ProductClicked, {
          currency: product.priceCurrency,
          eventType: 'click',
          origin: 'watchlist',
          productId: product.gtin,
          ...product,
        })
      },
      trackProductListViewed({
        products,
        category,
      }: ProductListViewedMetadata) {
        const trackedProducts = products.map((product) => {
          return {
            productId: product.gtin,
            sku: product.gtin,
            category: product.categoryName,
            name: product.name,
            brand: product.brandName,
            price: isNewPricingModelVariantSearch(product)
              ? undefined
              : product.price,
            minPrice: isNewPricingModelVariantSearch(product)
              ? product.minPrice
              : undefined,
            quantity: product.inventory,
            image_url: product.imageUrl,
            url: `/products/${product.fid}/${product.slug}`,
            isNewPricingModelVariantSearch:
              isNewPricingModelVariantSearch(product),
          }
        })
        track(AnalyticsEvents.ProductListViewed, {
          category,
          products: trackedProducts,
        })

        tikTokEvent('ViewContent', {
          contents: trackedProducts.map((product) => ({
            content_id: product.productId,
            content_name: product.name,
            content_type: 'product',
          })),
          value: trackedProducts.reduce(
            (accumulator, product) =>
              accumulator + (Number(product.price) || 0),
            0,
          ),
          currency: user?.currency || 'EUR',
          event_id: trackedProducts
            .map((product) => product.productId)
            .join(''),
        })

        tikTokEventApiEvent({
          event: 'ViewContent',
          event_id: trackedProducts
            .map((product) => product.productId)
            .join(''),
          user: {
            email: user?.email,
            phone: user?.phone,
          },
          properties: {
            contents: trackedProducts.map((product) => ({
              content_id: product.productId,
              content_name: product.name,
            })),
            content_type: 'product',
            value: trackedProducts.reduce(
              (accumulator, product) =>
                accumulator + (Number(product.price) || 0),
              0,
            ),
            currency: user?.currency || 'EUR',
          },
        })

        metaConversionEvent({
          event_id: trackedProducts
            .map((product) => product.productId)
            .join(''),
          event_name: 'ViewContent',
          custom_data: {
            content_ids: trackedProducts.map((product) => product.productId),
            content_type: 'product',
          },
          original_event_data: {
            event_name: 'ViewContent',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })

        metaEvent(
          'ViewContent',
          {
            content_ids: trackedProducts.map((product) => product.productId),
            content_type: 'product',
            contents: trackedProducts.map((product) => ({
              id: product.productId,
              quantity: product.quantity ?? 1,
            })),
            content_category: category,
          },
          {
            eventID: trackedProducts
              .map((product) => product.productId)
              .join(''),
          },
        )

        gtagEvent('view_item_list', {
          items: trackedProducts.map((product) => ({
            item_id: product.productId,
            item_name: product.name,
            item_brand: product.brand,
            item_category: product.category,
            price: product.price,
            quantity: 1,
          })),
        })
      },
      trackProductListFiltered({
        filters,
        products,
        searchHash,
        taxonomy,
      }: ProductListFilteredMetadata) {
        const trackedProducts = products.map((product) => {
          return {
            productId: product.gtin,
            sku: product.gtin,
            category: product.categoryName,
            name: product.name,
            brand: product.brandName,
            price: isNewPricingModelVariantSearch(product)
              ? undefined
              : product.price,
            minPrice: isNewPricingModelVariantSearch(product)
              ? product.minPrice
              : undefined,
            quantity: product.inventory,
            image_url: product.imageUrl,
            url: `/products/${product.fid}/${product.slug}`,
            hasDeals: isNewPricingModelVariantSearch(product)
              ? undefined
              : Boolean(product.deals),
            position: product.position,
            isNewPricingModelVariantSearch:
              isNewPricingModelVariantSearch(product),
          }
        })
        track(AnalyticsEvents.ProductListFiltered, {
          filters,
          products: trackedProducts,
          origin: 'catalog',
          searchHash,
          taxonomy,
        })
      },
      trackProductViewed({ variant }: { variant: VariantWeb }) {
        const productToTrack = getProductDetailsFromVariantWeb({ variant })
        track(AnalyticsEvents.ProductViewed, productToTrack)

        tikTokEvent('ViewContent', {
          contents: [
            {
              content_id: productToTrack.productId,
              content_name: productToTrack.name,
              content_type: 'product',
            },
          ],
          value: Number(productToTrack.price) || 0,
          currency: user?.currency || 'EUR',
          event_id: productToTrack.productId,
        })

        tikTokEventApiEvent({
          event: 'ViewContent',
          event_id: productToTrack.productId,
          user: {
            email: user?.email,
            phone: user?.phone,
          },
          properties: {
            contents: [
              {
                content_id: productToTrack.productId,
                content_name: productToTrack.name,
              },
            ],
            content_type: 'product',
            value: Number(productToTrack.price) || 0,
            currency: user?.currency || 'EUR',
          },
        })

        metaConversionEvent({
          event_id: productToTrack.productId,
          event_name: 'ViewContent',
          custom_data: {
            content_ids: [productToTrack.productId],
            content_type: 'product',
            content_name: productToTrack.name,
            value: Number(productToTrack.price),
            currency: productToTrack.currency ?? undefined,
          },
          original_event_data: {
            event_name: 'ViewContent',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })

        metaEvent(
          'ViewContent',
          {
            content_ids: [productToTrack.productId],
            content_type: 'product',
            content_name: productToTrack.name,
            value: Number(productToTrack.price),
            currency: productToTrack.currency ?? undefined,
          },
          { eventID: productToTrack.productId },
        )

        gtagEvent('view_item', {
          items: [
            {
              item_id: productToTrack.productId,
              item_name: productToTrack.name,
              item_brand: productToTrack.brand,
              item_category: productToTrack.category,
              price: productToTrack.price,
              quantity: 1,
            },
          ],
          currency: productToTrack.currency,
          value: productToTrack.price,
        })
      },
      isNewPricingModelVariantSearchtrackCartViewed() {
        bingEvent(AnalyticsEvents.CartViewed)
      },
      trackCheckoutStarted({ checkout }: TrackCheckoutStartedMetadata) {
        track(AnalyticsEvents.CheckoutStarted, {
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          // This is replicated to add the `value` key
          // enabling Segment to automatically map this to Google Ads
          value: checkout.subtotal,
          shipping: checkout.shipping,
          isDeal: checkout.isDeal,
          pricingModel: checkout.pricingModel,
        })
        tikTokEvent('InitiateCheckout', {
          contents: [
            {
              content_id: checkout.qid,
              content_name: checkout.cartQid,
              content_type: 'product',
            },
          ],
          value: Number(checkout.subtotal),
          currency: checkout.currency,
          event_id: checkout.qid,
        })
        tikTokEventApiEvent({
          event: 'InitiateCheckout',
          event_id: checkout.qid,
          properties: {
            value: Number(checkout.subtotal),
            currency: checkout.currency,
            content_type: 'product',
          },
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.CheckoutStarted)
        metaConversionEvent({
          event_id: checkout.qid,
          event_name: 'InitiateCheckout',
          custom_data: {
            value: Number(checkout.subtotal),
            currency: checkout.currency,
          },
          original_event_data: {
            event_name: 'InitiateCheckout',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaEvent(
          'InitiateCheckout',
          {
            value: Number(checkout.subtotal),
            currency: checkout.currency,
          },
          {
            eventID: checkout.qid,
          },
        )
        gtagEvent('begin_checkout', {
          currency: checkout.currency,
          value: checkout.subtotal,
        })
        gtagConversion(GOOGLE_CONVERSION_IDS.CheckoutStarted, {
          currency: checkout.currency,
          value: Number(checkout.subtotal),
          transaction_id: checkout.qid,
        })
      },
      trackFirstCheckoutStarted({
        checkout,
      }: TrackFirstCheckoutStartedMetadata) {
        tikTokCustomEvent('FirstCheckoutStarted', {
          value: Number(checkout.subtotal),
          currency: checkout.currency,
          order_id: checkout.qid,
          event_id: checkout.qid,
        })
        tikTokEventApiEvent({
          event: 'FirstCheckoutStarted',
          event_id: checkout.qid,
          properties: {
            value: Number(checkout.subtotal),
            currency: checkout.currency,
            content_type: 'product',
          },
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        trackLinkedInConversion('FirstCheckoutStarted')

        bingEvent(AnalyticsEvents.FirstCheckoutStarted)

        metaConversionEvent({
          event_id: checkout.qid,
          event_name: AnalyticsEvents.FirstCheckoutStarted,
          custom_data: {
            value: checkout.subtotal,
            currency: checkout.currency,
            order_id: checkout.qid,
          },
          original_event_data: {
            event_name: AnalyticsEvents.FirstCheckoutStarted,
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })

        metaCustomEvent(
          AnalyticsEvents.FirstCheckoutStarted,
          {
            value: checkout.subtotal,
            currency: checkout.currency,
            order_id: checkout.qid,
          },
          {
            eventID: checkout.qid,
          },
        )

        gtagEvent('first_checkout_started', {
          value: checkout.subtotal,
          currency: checkout.currency,
          order_id: checkout.qid,
        })
        gtagConversion(GOOGLE_CONVERSION_IDS.FirstCheckoutStarted, {
          currency: checkout.currency,
          value: Number(checkout.subtotal),
          transaction_id: checkout.qid,
        })
      },
      trackCheckoutStepCompleted({
        step,
        checkout,
      }: TrackCheckoutStepCompleted) {
        const stepNameMap = {
          address: 'Address',
          paymentMethod: 'Financing & payment',
        } as const
        const stepName = stepNameMap[step]

        track(AnalyticsEvents.CheckoutStepCompleted, {
          step: stepName,
          cartId: checkout.cartQid,
          orderId: checkout.qid,
          isDeal: checkout.isDeal,
          pricingModel: checkout.pricingModel,
        })
        if (stepName === 'Address') {
          gtagEvent('checkout_step_completed_address')
        }
        if (stepName === 'Financing & payment') {
          gtagEvent('checkout_step_completed_financing_payment')
        }
      },
      trackCheckoutCompleted({ order }: TrackCheckoutCompletedMetadata) {
        // I'm not a huge fan of the explicit type casting here but I can't
        // get declaration merging to work properly with the const declaration of datadogRum
        // otherwise I'd make a datadogRum.d.ts and declare the same module so TS can merge
        const datadogContextOrders = datadogRum.getGlobalContext().orders as
          | {
              fids: string[]
              qids: string[]
            }
          | undefined
        const { fid, qid } = order

        // This structure is slightly strange but it's required by Datadog to allow
        // us to use these values as facets when querying RUM sessions
        // https://docs.datadoghq.com/logs/explorer/search_syntax/#arrays
        datadogRum.setGlobalContextProperty('orders', {
          fids: [...(datadogContextOrders?.fids ?? []), fid],
          qids: [...(datadogContextOrders?.qids ?? []), qid],
        })

        trackLinkedInConversion('CheckoutCompleted')
        const productsToTrack = order.orderlines.map((orderline) => ({
          ...getProductDetailsFromVariant({
            variant: {
              ...orderline.variant,
              price: orderline.price,
              priceCurrency: orderline.priceCurrency,
            },
          }),
          quantity: orderline.quantity,
        }))

        tikTokEvent('PlaceAnOrder', {
          contents: productsToTrack.map((product) => ({
            content_id: product.productId,
            content_name: product.name,
            content_type: 'product',
          })),
          value: Number(order.total),
          currency: order.totalCurrency,
          event_id: order.qid,
        })
        tikTokEventApiEvent({
          event: 'PlaceAnOrder',
          event_id: order.qid,
          properties: {
            value: Number(order.total),
            currency: order.totalCurrency,
            content_type: 'product',
            content_ids: order.orderlines.map(
              (orderline) => orderline.variant.gtin,
            ),
            num_items: order.orderlines.length,
            contents: order.orderlines.map((orderline) => ({
              content_id: orderline.variant.gtin,
              content_name: orderline.variant.name,
              content_category: String(orderline.variant.category),
              price: Number(orderline.price),
              brand: String(orderline.variant.brand),
            })),
          },
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.CheckoutCompleted)
        metaConversionEvent({
          event_id: order.qid,
          event_name: 'Purchase',
          custom_data: {
            value: Number(order.total),
            currency: order.totalCurrency,
            order_id: order.qid,
            content_ids: productsToTrack.map((product) => product.productId),
            num_items: productsToTrack.length,
          },
          original_event_data: {
            event_name: 'Purchase',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaEvent(
          'Purchase',
          {
            value: Number(order.total),
            currency: order.totalCurrency,
            order_id: order.qid,
            content_ids: productsToTrack.map((product) => product.productId),
            num_items: productsToTrack.length,
          },
          {
            eventID: order.qid,
          },
        )
        gtagEvent('checkout_step_completed_review', {
          value: order.total,
          currency: order.totalCurrency,
        })
        gtagEvent('purchase', {
          currency: order.totalCurrency,
          items: productsToTrack.map((product) => ({
            item_id: product.productId,
            item_name: product.name,
            item_brand: product.brand,
            item_category: product.category,
            price: product.price,
            quantity: product.quantity,
          })),
          transaction_id: order.qid,
          shipping: order.shipping,
          tax: order.vat,
          value: order.total,
        })
        gtagConversion(GOOGLE_CONVERSION_IDS.CheckoutCompleted, {
          currency: order.totalCurrency,
          value: Number(order.total),
          transaction_id: order.qid,
        })
      },
      trackFirstCheckoutCompleted({
        order,
        email,
      }: TrackFirstCheckoutCompletedMetadata) {
        trackLinkedInConversion('FirstCheckoutCompleted')

        tikTokCustomEvent('FirstCheckoutCompleted', {
          value: Number(order.total),
          currency: order.totalCurrency,
          order_id: order.qid,
          event_id: order.qid,
        })
        tikTokEventApiEvent({
          event: 'FirstCheckoutCompleted',
          event_id: order.qid,
          properties: {
            value: Number(order.total),
            currency: order.totalCurrency,
            content_type: 'product',
            content_ids: order.orderlines.map(
              (orderline) => orderline.variant.gtin,
            ),
            num_items: order.orderlines.length,
            contents: order.orderlines.map((orderline) => ({
              content_id: orderline.variant.gtin,
              content_name: orderline.variant.name,
              content_category: String(orderline.variant.category),
              price: Number(orderline.price),
              brand: String(orderline.variant.brand),
            })),
          },
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.FirstCheckoutCompleted)
        metaConversionEvent({
          event_id: order.qid,
          event_name: AnalyticsEvents.FirstCheckoutCompleted,
          custom_data: {
            value: Number(order.total),
            currency: order.totalCurrency,
            order_id: order.qid,
          },
          original_event_data: {
            event_name: AnalyticsEvents.FirstCheckoutCompleted,
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaCustomEvent(
          AnalyticsEvents.FirstCheckoutCompleted,
          {
            value: order.total,
            currency: order.totalCurrency,
            order_id: order.qid,
          },
          {
            eventID: order.qid,
          },
        )
        gtagEvent('first_time_purchase', {
          order_id: order.qid,
          currency: order.totalCurrency,
          value: order.total,
        })
        gtagConversion(GOOGLE_CONVERSION_IDS.FirstCheckoutCompleted, {
          currency: order.totalCurrency,
          value: Number(order.total),
          transaction_id: order.qid,
        })
        tapfiliateConversion({
          orderQid: order.qid,
          amount: Number(order.total),
          email,
        })
      },
      trackUserSignedIn({ email }: TrackUserSignedInMetadata) {
        track(AnalyticsEvents.UserSignedIn, {
          username: email,
        })
        gtagEvent('login')
      },
      trackUserSignedOut() {
        track(AnalyticsEvents.UserSignedOut)
      },
      trackRegistrationStepCompleted({
        step,
      }: RegistrationStepCompletedMetadata) {
        track(AnalyticsEvents.RegistrationStepCompleted, {
          step,
        })
        bingEvent(AnalyticsEvents.RegistrationStepCompleted, {
          step,
        })
      },
      trackUserSignedUp({
        email,
        account,
        company,
        personaDistributionChannels,
        annualTurnover,
        numberOfEmployees,
      }: TrackUserSignedUpMetadata) {
        trackLinkedInConversion('UserSignedUp')
        track(AnalyticsEvents.UserSignedUp, {
          account,
          email,
          company,
          personaDistributionChannels,
          annualTurnover,
          numberOfEmployees,
        })
        tikTokEvent('CompleteRegistration', { event_id: email })
        tikTokEventApiEvent({
          event: 'CompleteRegistration',
          event_id: email,
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.UserSignedUp)
        metaConversionEvent({
          event_id: email,
          event_name: 'CompleteRegistration',
          original_event_data: {
            event_name: 'CompleteRegistration',
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaEvent('CompleteRegistration', {}, { eventID: email })
        gtagEvent('sign_up')
        gtagConversion(GOOGLE_CONVERSION_IDS.UserSignedUp)
        tapfiliateSignupUser({ email })
      },
      trackUserSignedUpQualified({
        email,
        account,
        company,
        personaDistributionChannels,
        annualTurnover,
        numberOfEmployees,
      }: TrackUserSignedUpMetadata) {
        trackLinkedInConversion('UserSignedUpQualified')
        track(AnalyticsEvents.UserSignedUpQualified, {
          account,
          email,
          company,
          personaDistributionChannels,
          annualTurnover,
          numberOfEmployees,
        })
        tikTokCustomEvent('UserSignedUpQualified', { event_id: email })
        tikTokEventApiEvent({
          event: 'UserSignedUpQualified',
          event_id: email,
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.UserSignedUpQualified)
        metaConversionEvent({
          event_id: email,
          event_name: AnalyticsEvents.UserSignedUpQualified,
          original_event_data: {
            event_name: AnalyticsEvents.UserSignedUpQualified,
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })

        metaCustomEvent(
          AnalyticsEvents.UserSignedUpQualified,
          {},
          { eventID: email },
        )
        gtagEvent('sign_up_qualified')
        gtagConversion(GOOGLE_CONVERSION_IDS.UserSignedUpQualified)
      },
      trackUserVerificationStatusChanged({
        status,
        userId,
        verification,
      }: TrackUserVerificationStatusChangedMetadata) {
        const metaUuid = self.crypto.randomUUID()
        track(AnalyticsEvents.UserVerificationStatusChanged, {
          status,
          userId,
          verification,
        })
        tikTokCustomEvent('UserVerificationStatusChanged', {
          status,
          event_id: userId ?? metaUuid,
        })
        tikTokEventApiEvent({
          event: 'UserVerificationStatusChanged',
          event_id: userId ?? metaUuid,
          user: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        bingEvent(AnalyticsEvents.UserVerificationStatusChanged, {
          status,
        })
        metaConversionEvent({
          event_id: userId ?? metaUuid,
          event_name: AnalyticsEvents.UserVerificationStatusChanged,
          custom_data: {
            status,
          },
          original_event_data: {
            event_name: AnalyticsEvents.UserVerificationStatusChanged,
          },
          user_data: {
            email: user?.email,
            phone: user?.phone,
          },
        })
        metaCustomEvent(
          AnalyticsEvents.UserVerificationStatusChanged,
          {
            status,
          },
          { eventID: userId ?? metaUuid },
        )
        gtagConversion(GOOGLE_CONVERSION_IDS.UserVerificationStatusChanged)
      },
      trackShippingBannerModalViewed() {
        track(AnalyticsEvents.ShippingBannerModalViewed)
      },
      trackAccountMenuOpened() {
        track(AnalyticsEvents.AccountMenuOpened)
      },
      trackNavigationMenuOpened(meta: TrackNavigationMenuOpened) {
        track(AnalyticsEvents.NavigationMenuOpened, meta)
      },
      trackAccountManagerContactClicked({
        medium,
      }: TrackAccountManagerContactClickedMetadata) {
        track(AnalyticsEvents.AccountManagerContactClicked, {
          medium,
        })
      },
      trackProductWatchlisted({ product }: TrackProductWatchlistedMetadata) {
        track(AnalyticsEvents.ProductWatchlisted, {
          gtin: product.gtin,
          name: product.name,
          price: 'minPrice' in product ? undefined : product.price, // this has VariantWeb that makes the helper not useful
          minPrice: 'minPrice' in product ? product.minPrice : undefined,
          currency:
            'minPriceCurrency' in product // this has VariantWeb that makes the helper not useful
              ? undefined
              : product.priceCurrency,
          minPriceCurrency:
            'minPriceCurrency' in product // this has VariantWeb that makes the helper not useful
              ? product.minPriceCurrency
              : undefined,
          isNewPricingModelVariantSearch: 'minPrice' in product, // this has VariantWeb that makes the helper not useful
        })
      },
      trackWatchlistDownloaded() {
        track(AnalyticsEvents.WatchlistDownloaded)
      },
      trackWatchlistUploaded() {
        track(AnalyticsEvents.WatchlistUploaded)
      },
      trackTargetPriceAdded({
        gtin,
        targetPrice,
        currency,
        origin,
      }: {
        gtin: string
        targetPrice: string
        currency: BuyerCurrency
        origin: string
      }) {
        track(AnalyticsEvents.TargetPriceAdded, {
          gtin,
          targetPrice,
          currency,
          origin,
        })
      },
      trackTargetPriceEdited({
        gtin,
        targetPrice,
        currency,
        origin,
      }: {
        gtin: string
        targetPrice: string | null
        currency: BuyerCurrency
        origin: string
      }) {
        track(AnalyticsEvents.TargetPriceEdited, {
          gtin,
          targetPrice,
          currency,
          origin,
        })
      },
      trackTargetQuantityAdded({
        gtin,
        targetQuantity,
        origin,
      }: {
        gtin: string
        targetQuantity: number
        origin: string
      }) {
        track(AnalyticsEvents.TargetQuantityAdded, {
          gtin,
          targetQuantity,
          origin,
        })
      },
      trackTargetQuantityEdited({
        gtin,
        targetQuantity,
        origin,
      }: {
        gtin: string
        targetQuantity: number | null
        origin: string
      }) {
        track(AnalyticsEvents.TargetQuantityEdited, {
          gtin,
          targetQuantity,
          origin,
        })
      },
      trackSellViaQogitaLinkClicked({ origin }: { origin: string }) {
        track(AnalyticsEvents.SellViaQogitaLinkClicked, {
          origin,
        })
      },
      trackManualSourcingLinkClicked({ origin }: { origin: string }) {
        track(AnalyticsEvents.ManualSourcingLinkClicked, {
          origin,
        })
      },
      trackCartMovRecommendationsLinkClicked({
        gtin,
        allocationStatus,
        cartQid,
      }: {
        gtin: string
        allocationStatus: AllocationLineAllocationStatus
        cartQid: string
      }) {
        track(AnalyticsEvents.CartMovRecommendationsLinkClicked, {
          gtin,
          allocationStatus,
          cartQid,
        })
      },
      trackWhyAmIBeingChargedVatLinkClicked({
        checkout,
      }: TrackWhyAmIBeingChargedVatLinkClicked) {
        track(AnalyticsEvents.WhyAmIBeingChargedVatLinkClicked, {
          cartQid: checkout.cartQid,
          orderQid: checkout.qid,
          currency: checkout.currency,
          subtotal: checkout.subtotal,
          isDeal: checkout.isDeal,
          vat: checkout.vat,
          shipping: checkout.shipping,
          billingAddressCountry: checkout.billingAddress?.country,
          shippingAddressCountry: checkout.shippingAddress?.country,
        })
      },
      trackCartExpiryAlertViewed({
        cartQid,
        page,
      }: {
        cartQid: string
        page: string
      }) {
        track(AnalyticsEvents.CartExpiryAlertViewed, {
          cartQid,
          page,
        })
      },
      trackCheckoutHasChangesAlertViewed({
        cartQid,
        page,
      }: {
        cartQid: string
        page: string
      }) {
        track(AnalyticsEvents.CheckoutHasChangesAlertViewed, {
          cartQid,
          page,
        })
      },
      trackButtonClicked({
        name,
        ...parameters
      }: { name: string; origin?: EventOrigin } & Record<
        Exclude<string, 'name' | 'origin'>,
        string | number | boolean | Record<string, unknown> | undefined | null
      >) {
        track(AnalyticsEvents.ButtonClicked, {
          name,
          ...parameters,
        })
      },
      trackBrandRecommendationClicked(
        props: {
          recommendedBrand: { name: string; slug: string }
          origin: EventOrigin
          position?: number
        } & (
          | { brandViewing: { name: string; slug: string } }
          | { gtinViewing: string }
        ),
      ) {
        tracking.trackButtonClicked({
          name: 'Brand Recommendation',
          ...props,
        })
      },
      trackProductListEmptyStateViewed() {
        track(AnalyticsEvents.ProductListEmptyStateViewed)
      },
      trackProductsShowOnlyWatchlistedToggled(value: boolean) {
        track(AnalyticsEvents.ProductsShowOnlyWatchlistedToggled, { value })
      },
    }

    return tracking
  }, [track, user])
}

export const trackUserSignedOut = () => {
  try {
    track(AnalyticsEvents.UserSignedOut)
  } catch (error) {
    logClientError(error as Error)
  }
}

export function useTrackProductListFiltered({
  products,
  searchOptions,
  searchHash,
  currency,
}: {
  products: VariantSearch[] | VariantOffersSearch[]
  searchOptions: Pick<
    GetVariantsSearchSearchParams,
    | 'query'
    | 'categories'
    | 'brands'
    | 'size'
    | 'hasDeals'
    | 'minPrice'
    | 'maxPrice'
    | 'showWatchlistedOnly'
  >
  searchHash: string
  currency: User['currency']
}) {
  const { trackProductListFiltered } = useTrackEvent()
  const newTaxonomyEnabled = useNewTaxonomyEnabled()

  const previousProducts = usePrevious(products)
  const {
    query,
    categories,
    brands,
    size,
    hasDeals,
    minPrice,
    maxPrice,
    showWatchlistedOnly,
  } = searchOptions

  useEffect(() => {
    if (!products.length || isDeepEqual(previousProducts, products)) {
      return
    }

    const filters = []

    if (query) {
      filters.push({
        type: 'query',
        value: query,
      })
    }

    if (categories) {
      filters.push({
        type: 'category',
        value: categories,
      })
    }

    if (brands?.length) {
      filters.push({
        type: 'brand',
        value: brands,
      })
    }

    if (size) {
      filters.push({
        type: 'hits_per_page',
        value: String(size),
      })
    }

    if (hasDeals) {
      filters.push({
        type: 'deals',
        value: hasDeals,
      })
    }

    if (showWatchlistedOnly) {
      filters.push({
        type: 'show_watchlisted_only',
        value: showWatchlistedOnly,
      })
    }

    if (minPrice) {
      filters.push({
        type: 'min_price',
        value: getFormattedCurrencyWithSymbol(
          minPrice,
          'en-GB',
          getValidatedUserCurrency(currency),
        ),
      })
    }

    if (maxPrice) {
      filters.push({
        type: 'max_price',
        value: getFormattedCurrencyWithSymbol(
          maxPrice,
          'en-GB',
          getValidatedUserCurrency(currency),
        ),
      })
    }

    // By definition if we have no filters applied
    // then the product list has not been filtered
    // so we don't need to track this event
    if (filters.length === 0) return

    trackProductListFiltered({
      filters,
      products,
      searchHash,
      taxonomy: newTaxonomyEnabled ? 'v2' : 'v1',
    })
  }, [
    brands,
    categories,
    currency,
    hasDeals,
    maxPrice,
    minPrice,
    previousProducts,
    products,
    query,
    searchHash,
    size,
    trackProductListFiltered,
    newTaxonomyEnabled,
    showWatchlistedOnly,
  ])
}

export function useTrackProductListViewed({
  products,
}: {
  products: VariantSearch[] | VariantOffersSearch[]
}) {
  const { trackProductListViewed } = useTrackEvent()

  const previousProducts = usePrevious(products)

  useEffect(() => {
    // Since products is an array, we need to do a deep compare as we only want to track
    // if the contents of the array have changed
    if (!products.length || isDeepEqual(previousProducts, products)) return

    trackProductListViewed({
      products,
    })
  }, [previousProducts, products, trackProductListViewed])
}
