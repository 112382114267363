import { OrderRetrieve } from '@qogita/canary-types'
import { cn } from '@qogita/ui/utils/cn'
import {
  CurrencyFormatterOptions,
  getCurrencyFormatter,
} from 'src/deprecated/lib/currency'

/**
 * @deprecated Use `Money` component instead.
 * Format a currency amount in accounting format with currency symbol
 */
export function CurrencyAmount({
  amount,
  currency,
  className,
  options,
}: {
  amount: number | string
  currency: OrderRetrieve['currency']
  className?: string
  options?: CurrencyFormatterOptions
}) {
  const currencyFormatter = getCurrencyFormatter(currency, options)
  return (
    <span className={cn('whitespace-nowrap', className)}>
      {currencyFormatter(amount)}
    </span>
  )
}
