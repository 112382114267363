import { StarIcon } from '@heroicons/react/24/solid'
import { storyblokEditable } from '@storyblok/react'
import { format } from 'date-fns'
import Image from 'next/image'
import React, { useRef, useState } from 'react'
import { DeprecatedTestimonialsRichMediaCardStoryblok } from 'src/core/cms/storyblok-component-types'

import { storyblokImageLoader } from './storyblokImageLoader'

interface StarRatingProps {
  rating: number
  maxRating?: number
}

const StarRating = ({ rating, maxRating = 5 }: StarRatingProps) => {
  return (
    <div className="flex">
      {Array.from({ length: maxRating }).map((_, index) => (
        <StarIcon
          key={index}
          className={`h-4 w-4 ${
            index < rating ? 'text-primary-700' : 'text-gray-200'
          }`}
        />
      ))}
    </div>
  )
}

const VideoTestimonialCard = ({
  blok,
}: {
  blok: DeprecatedTestimonialsRichMediaCardStoryblok
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const formattedDate = format(new Date(blok.date), 'MMM dd, yyyy')
  const videoPosterImage = blok.videoPosterImage?.filename

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col rounded-lg border border-gray-200 bg-white shadow-sm md:w-1/3"
    >
      <div className="relative mb-4 h-[220px] w-full overflow-hidden rounded-t-lg">
        <video
          poster={videoPosterImage ?? undefined}
          preload="auto"
          className="h-full w-full object-cover"
          playsInline
          controls={false}
          ref={videoRef}
        >
          <source src={blok.media.filename} type="video/mp4" />
        </video>
        <div className="pointer-events-none absolute bottom-0 flex w-full items-center justify-between bg-gradient-to-b from-transparent to-gray-800/60 px-6 py-4 text-white">
          <div className="flex flex-col gap-2">
            <StarRating rating={Number(blok.rating)} />
            <div>
              <h3 className="font-semibold">{blok.name}</h3>
              <p className="max-w-[155px] text-sm">{blok.jobTitle}</p>
            </div>
          </div>
          <button
            onClick={() => {
              if (videoRef.current && !videoRef.current.paused) {
                videoRef.current.pause()
                setIsPlaying(false)
              } else {
                videoRef.current?.play()
                setIsPlaying(true)
              }
            }}
            className="pointer-events-auto flex h-10 w-10 items-center justify-center rounded-full bg-white"
          >
            {isPlaying ? (
              <div className="flex gap-1">
                <div className="h-[16px] w-[4px] bg-gray-900" />
                <div className="h-[16px] w-[4px] bg-gray-900" />
              </div>
            ) : (
              <div
                className="h-0 w-0 border-y-[8px] border-l-[16px] border-r-0 border-solid border-y-transparent border-l-gray-900"
                style={{ marginLeft: '3px' }}
              />
            )}
          </button>
        </div>
      </div>
      <div className="px-6 pb-6 pt-1">
        <p>&quot;{blok.testimonial}&quot;</p>
        <p className="mt-4 text-sm text-gray-500">{formattedDate}</p>
      </div>
    </div>
  )
}

const ImageTestimonialCard = ({
  blok,
}: {
  blok: DeprecatedTestimonialsRichMediaCardStoryblok
}) => {
  const formattedDate = format(new Date(blok.date), 'MMM dd, yyyy')

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-grow-0 flex-col rounded-lg border border-gray-200 bg-white p-6 shadow-sm md:w-1/3"
    >
      <div className="mb-4 flex items-center gap-3">
        <Image
          loader={storyblokImageLoader}
          src={blok.media.filename}
          alt={blok.media.alt || `${blok.name}'s avatar`}
          width={48}
          height={48}
          className="h-12 w-12 rounded-full object-cover"
        />
        <div>
          <h3 className="font-semibold">{blok.name}</h3>
          <p className="max-w-[155px] text-sm">{blok.jobTitle}</p>
        </div>
      </div>

      <div className="mb-4">
        <StarRating rating={Number(blok.rating)} />
      </div>

      <p>&quot;{blok.testimonial}&quot;</p>

      <p className="mt-4 text-sm text-gray-500">{formattedDate}</p>
    </div>
  )
}

export const TestimonialsRichMediaCard = ({
  blok,
}: {
  blok: DeprecatedTestimonialsRichMediaCardStoryblok
}) => {
  const isVideo =
    blok.media.filename.includes('.mp4') ||
    blok.media.filename.includes('.webm') ||
    blok.media.filename.includes('.mov')

  if (isVideo) {
    return <VideoTestimonialCard blok={blok} />
  }

  return <ImageTestimonialCard blok={blok} />
}
