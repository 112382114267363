import { datadogRum } from '@datadog/browser-rum'
import { useCanaryClient } from '@qogita/canary-client/provider'
import { OrderRetrieve, User } from '@qogita/canary-types'
import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useAuthentication } from 'src/core/authentication/provider'
import { getCartQueries } from 'src/deprecated/api/cart-queries'

// This is an example usage of the useFlags hook
// Keeping it here so we don't end up with a disallowed empty file when we don't currently have
// any flags in use
// ts-unused-exports:disable-next-line
export const useExampleFlag = () => {
  const flags = useFlags()

  return flags.exampleFlag === true
}

export const useOptimizerIncreaseQuantityForUnitConstraints = () => {
  const flags = useFlags()

  return flags.optimizerIncreaseQuantityForUnitConstraints === true
}

export const useIsPdpShippingFromCountryFlagVisible = () => {
  const flags = useFlags()

  return flags.frontendBuyersPdpShowShippingFromCountryFlag === true
}

export const useNewTaxonomyEnabled = () => {
  const flags = useFlags()

  return flags.newTaxonomyEnabled === true
}

export const useCatalogDownloadsFromAthenaEnabled = () => {
  const flags = useFlags()

  return flags.enableCatalogDownloadsFromAthena === true
}

/**
 * The new pricing model flag is based on the user, cart, or order.
 *
 * We use the "cart" one before purchase, and the "order" one after purchase.
 *
 * There is no "user" use case yet.
 */
export const useIsActiveCartOfferBasedModel = () => {
  const { isAuthenticated } = useAuthentication()
  const canaryClient = useCanaryClient()
  const { data } = useQuery({
    ...getCartQueries(canaryClient).activeDetail(),
    enabled: isAuthenticated,
  })

  useEffect(() => {
    if (data?.pricingModel) {
      datadogRum.addFeatureFlagEvaluation(
        'cart-pricing-model',
        data.pricingModel,
      )
    }
  }, [data?.pricingModel])

  return data?.pricingModel === 'Offer Based'
}

export const useEvaluateOrderPricingModelFeatureFlag = (
  order?: OrderRetrieve,
) => {
  useEffect(() => {
    if (order?.pricingModel) {
      datadogRum.addFeatureFlagEvaluation(
        'order-pricing-model',
        order.pricingModel,
      )
    }
  }, [order?.pricingModel])
}

export const useEvaluateUserPricingModelFeatureFlag = (user?: User) => {
  useEffect(() => {
    if (user?.pricingModel) {
      datadogRum.addFeatureFlagEvaluation(
        'user-pricing-model',
        user.pricingModel,
      )
    }
  }, [user?.pricingModel])
}

export const useIsAppRouterReferralsPageEnabled = () => {
  const flags = useFlags()

  return flags.appRouterReferralsPage === true
}

export const useProductFruitsEnabled = () => {
  const flags = useFlags()
  return Boolean(flags.buyersProductFruitsEnabled)
}

export const useIsLockMonduAccountDetails = () => {
  const flags = useFlags()
  return Boolean(flags.lockMonduAccountDetails)
}

export const useCartDownloadForOfferBasedEnabled = () => {
  const flags = useFlags()
  return Boolean(flags.cartDownloadForOfferBased)
}

export const useIsBuyBotProEnabled = () => {
  const flags = useFlags()
  return Boolean(flags.enableBuyBotPro)
}

export const useEnableBetterWatchlistAlerts = () => {
  const flags = useFlags()
  return Boolean(flags.enableBetterWatchlistAlerts)
}
