import {
  AbaBankCreateRequest,
  Address,
  AddressRequest,
  Brand,
  BrandDiscountRequestRequest,
  BrandDiscountResponse,
  BrandNameFacetSearch,
  BrandRequestRequest,
  BrandsWithSellerDiscountsResponse,
  BuyerOrderStatus,
  Cart,
  CartAllocationChange,
  CartAllocationLineRequest,
  CartAllocationList,
  CartLine,
  CartLineRequest,
  Checkout,
  CheckoutCompleteRequest,
  CheckoutCompleteResponse,
  CheckoutValidationResponse,
  Claim,
  ClaimEvidenceQuestionEvaluationRequestRequest,
  ClaimEvidenceQuestionEvaluationResponse,
  ClaimRequest,
  Country,
  CreateMembershipRequest,
  CreateSellerLeadRequest,
  CurrentUser,
  EmailTokenVerify,
  EmailTokenVerifyRequest,
  EmailValidationPostRequest,
  EmailValidationPostResponse,
  File,
  FileRequest,
  IbanBankCreateRequest,
  InitialCheckRequestRequest,
  InitialCheckResponse,
  InitialCheckTaskResult,
  MonduApplication,
  MonduApplicationRequest,
  ObtainToken,
  ObtainTokenRequest,
  Offer,
  OfferCountByStatusStatus,
  OfferInfo,
  OfferSearch,
  OptimizeCartRequest,
  OrderClaim,
  type OrderRetrieve,
  OrderSaleIssueInputRequest,
  OrderStatus,
  PaginatedAddressList,
  PaginatedBrandContactList,
  PaginatedBrandList,
  PaginatedCartLineList,
  PaginatedCartSummaryList,
  PaginatedCategoryResponseList,
  PaginatedCheckoutLineList,
  PaginatedOfferList,
  PaginatedOffersRecommendationsSearch,
  PaginatedOffersSearch,
  PaginatedOrderLineShippedQuantitiesList,
  PaginatedOrderSalesList,
  PaginatedOrderSummaryList,
  PaginatedSaleLineGenericOutputList,
  PaginatedSaleList,
  PaginatedSearch,
  PaginatedShippingCarrierList,
  PaginatedWalletLineList,
  PaginatedWatchlistItemList,
  PasswordChange,
  PasswordChangeRequest,
  PatchedCartAllocationLineRequest,
  PatchedCartLinePatchRequest,
  PatchedCheckoutRequest,
  PatchedCurrentUserRequest,
  PatchedSaleLineGenericRequest,
  PatchedSaleRequest,
  PatchedWatchlistItemUpdateRequest,
  PhoneTokenVerify,
  PhoneTokenVerifyRequest,
  PhoneVerify,
  PhoneVerifyRequest,
  PhoneVerifyThrottleError as CanaryPhoneVerifyThrottleError,
  PriceHistory,
  RecommendedBrandsGetResponse,
  RefreshToken,
  RetrieveBank,
  Sale,
  SaleCarrierBookingInputRequest,
  SaleCarrierBookingOutput,
  SaleDeclineRequest,
  SaleIssueInputRequest,
  SaleLineGenericOutput,
  SaleLinesBulkUpdateInputRequest,
  SaleSearch,
  SearchDownloadRequest,
  SearchSuggestions,
  SellerFee,
  SellerFeeRequest,
  SellerLead,
  SellerLeadListItem,
  SellerRecommendationResponse,
  SellerShipmentsResponse,
  SellerStatsOutput,
  ShipmentInputRequest,
  ShippingCarrier,
  SortCodeBankCreateRequest,
  Statistics,
  UploadCartRequest,
  UploadWatchlistRequest,
  UsageTypes,
  UserCreate,
  UserCreateRequest,
  UserDebtor,
  UserGroups,
  ValidateTokenRequest,
  ValidateTokenResponse,
  VariantOffersList,
  VariantRequestRequest,
  VariantWeb,
  VATList,
  VatValidationPostRequestBodyRequest,
  VatValidationPostResponse,
  Wallet,
  WalletWithdrawalRequest,
  WatchlistItem,
  WatchlistItemCreateRequest,
} from '@qogita/canary-types'
import endpointsList from '@qogita/canary-types/endpoints'
import { parse, serialize } from 'cookie'
import ky, { BeforeRequestHook, HTTPError } from 'ky'
import { match } from 'path-to-regexp'
import { z } from 'zod'

/**
 * Convert from /path/{param}/path/{param} to /path/:param/path/:param
 * to make it compatible with the path-to-regexp lib
 * */
function openApiPathToPathRegex(path: string): string {
  return path.replaceAll(/{(\w+)}/g, ':$1')
}

const endpoints = z
  .string()
  .array()
  .parse(endpointsList)
  .map(openApiPathToPathRegex)

export class CanaryClientHttpError extends HTTPError {
  public fingerprint: string
  public details: {
    url: string
    pathPattern: string
    httpStatus: number
    method: string
    requestJson?: unknown
    responseJson?: unknown
  }

  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CanaryClientHttpError'
    this.stack = httpError.stack

    const request = httpError.request
    const response = httpError.response.clone()
    const options = httpError.options

    // Extract the path from the url, and make sure it starts with /
    let path = request.url.split('?')[0]
    path = path?.split(options.prefixUrl)[1] || '/'
    path = path.startsWith('/') ? path : `/${path}`

    // Match the path against the endpoints list
    const pathPattern =
      endpoints.find((pattern) => match(pattern)(path)) || 'UNSET'

    this.message = `[${response.status}] ${request.method} ${pathPattern}`
    this.fingerprint = `${request.method} ${pathPattern} ${response.status}`
    this.details = {
      url: request.url,
      httpStatus: response.status,
      method: request.method,
      pathPattern,
    }
  }

  private async getResponseJson() {
    const [data, error] = await this.response
      .clone()
      .json()
      .then((data) => [data, null])
      .catch((error) => [null, error])
    return { data, error } as { data: unknown | null; error: Error | null }
  }

  private async getRequestJson() {
    const [data, error] = await this.request
      .clone()
      .json()
      .then((data) => [data, null])
      .catch((error) => [null, error])
    return { data, error } as { data: unknown | null; error: Error | null }
  }

  /**
   * Set request and response data to log together with the error.
   */
  public async setDetailsData() {
    const [requestData, responseData] = await Promise.all([
      this.getRequestJson(),
      // the response is redcated on datadog with
      // https://app.datadoghq.eu/sensitive-data-scanner/configuration/
      this.getResponseJson(),
    ])
    this.details.requestJson = requestData
    this.details.responseJson = responseData
  }
}

export class CanaryClient {
  private readonly ky: typeof ky
  private readonly refresh:
    | {
        token: string
        cookieName: string
      }
    | undefined

  constructor({
    prefixUrl,
    accessToken,
    refresh,
    beforeRequest = [],
    xQogitaApplication = 'Canary Web Client',
    defaultHeaders = {},
    logHttpError,
  }: {
    prefixUrl: string
    accessToken?: string
    refresh?: {
      token: string
      cookieName: string
    }
    beforeRequest?: BeforeRequestHook[]
    /** Identifier of the application using the client to help with observability logging */
    xQogitaApplication?: string
    defaultHeaders?: Record<string, string>
    logHttpError?: (error: CanaryClientHttpError) => Promise<void> | void
  }) {
    this.ky = ky.create({
      prefixUrl,
      retry: {
        limit: 0, // We control retries in React Query
      },
      timeout: false, // We control timeouts in vercel
      credentials: 'include',
      headers: defaultHeaders,
      hooks: {
        beforeError: [
          async (httpError) => {
            const error = new CanaryClientHttpError(httpError)
            await error.setDetailsData()
            logHttpError?.(error)
            return error
          },
        ],
        beforeRequest: [
          (request) => {
            if (accessToken) {
              request.headers.set('Authorization', `Bearer ${accessToken}`)
            }
            if (refresh) {
              request.headers.set(
                'cookie',
                serialize(refresh.cookieName, refresh.token),
              )
            }
            request.headers.set('x-qogita-application', xQogitaApplication)
          },
          ...beforeRequest,
        ],
      },
    })
    this.refresh = refresh
  }

  // Shared methods
  // Address methods
  getAddresses({
    page,
    size,
    usageType,
  }: { page?: number; size?: number; usageType?: UsageTypes } = {}) {
    const searchParams = new URLSearchParams()
    if (page) {
      searchParams.append('page', String(page))
    }
    if (size) {
      searchParams.append('size', String(size))
    }
    if (usageType) {
      searchParams.append('usageTypes', usageType)
    }
    return this.ky
      .get(`addresses/?${searchParams.toString()}`)
      .json<PaginatedAddressList>()
  }
  getAddress(qid: string) {
    return this.ky.get(`addresses/${qid}/`).json<Address>()
  }
  updateAddress(qid: string, data: AddressRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) {
                return error
              }
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                updateAddressBadRequestErrorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new UpdateAddressBadRequestError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }
              return error
            },
          ],
        },
      })
      .put(`addresses/${qid}/`, { json: data })
      .json<Address>()
  }
  createAddress(data: AddressRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) {
                return error
              }
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                createAddressBadRequestErrorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new CreateAddressBadRequestError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }
              return error
            },
          ],
        },
      })
      .post(`addresses/`, { json: data })
      .json<Address>()
  }
  deleteAddress(qid: string) {
    return this.ky.delete(`addresses/${qid}/`).json<void>()
  }

  // Info methods
  getInfo() {
    return this.ky.get('info/').json<Statistics>()
  }
  // User methods
  getUser() {
    return this.ky.get('user/').json<CurrentUser>()
  }
  createUser(data: UserCreateRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              // We only have special error handling for 400s as anything else is simple
              if (error.response.status !== 400) return error

              const errorSchema = z.object({
                code: z.literal('invalid'),
                message: z.string(),
                lineNumber: z.array(z.string()).optional(),
                // There could be other fields here, but we don't care about them at the moment
                // Add them if you need them
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult = errorSchema.safeParse(errorBody)
              if (!parsedErrorBodyResult.success) return error
              return new CreateUserBadRequestError(
                error,
                parsedErrorBodyResult.data,
              )
            },
          ],
        },
      })
      .post('users/', { json: data })
      .json<UserCreate>()
  }
  updateUser(data: PatchedCurrentUserRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) return error
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                updateUserBadRequestErrorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new UpdateUserBadRequestError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }
              return error
            },
          ],
        },
      })
      .patch('user/', { json: data })
      .json<CurrentUser>()
  }
  updateUserPassword(data: PasswordChangeRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) return error

              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                updateUserPasswordBadRequestErrorSchema.safeParse(errorBody)

              if (parsedErrorBodyResult.success) {
                return new UpdateUserPasswordBadRequestError(error)
              }

              return error
            },
          ],
        },
      })
      .put('user/password/', { json: data, credentials: 'include' })
      .json<PasswordChange>()
  }
  getUserBankInfo() {
    return this.ky.get('user/bank/info/').json<RetrieveBank>()
  }
  createUserBankAba(data: AbaBankCreateRequest) {
    return this.ky.post('user/bank/aba/', { json: data }).json<RetrieveBank>()
  }
  createUserBankIban(data: IbanBankCreateRequest) {
    return this.ky.post('user/bank/iban/', { json: data }).json<RetrieveBank>()
  }
  createUserBankSortCode(data: SortCodeBankCreateRequest) {
    return this.ky
      .post('user/bank/sortcode/', { json: data })
      .json<RetrieveBank>()
  }
  getUserDebtor() {
    return this.ky.get('user/debtor/').json<UserDebtor>()
  }
  getUserGroups() {
    return this.ky.get('user/groups/').json<UserGroups>()
  }
  resendTokenEmail(data: ValidateTokenRequest) {
    return this.ky.post('user/email/resend-token/', { json: data }).json<void>()
  }
  requestVerifyUserEmail() {
    return this.ky.post('user/email/verify/').json<void>()
  }
  confirmVerifyUserEmail(data: EmailTokenVerifyRequest) {
    return this.ky
      .post('user/email/verify-token/', { json: data })
      .json<EmailTokenVerify>()
  }
  requestVerifyUserPhone(data: PhoneVerifyRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 429) return error
              const errorBody = (await error.response
                .clone()
                .json()) as CanaryPhoneVerifyThrottleError
              const waitSeconds = Number(errorBody.wait)
              return new PhoneVerifyThrottleError(error, { waitSeconds })
            },
          ],
        },
      })
      .post('user/phone/verify/', { json: data })
      .json<PhoneVerify>()
  }
  confirmVerifyUserPhone(data: PhoneTokenVerifyRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) {
                return error
              }
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                confirmVerifyUserPhoneBadRequestErrorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new ConfirmVerifyUserPhoneBadRequestError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }
              return error
            },
          ],
        },
      })
      .post('user/phone/verify-token/', { json: data })
      .json<PhoneTokenVerify>()
  }
  updateUserFee(data: SellerFeeRequest) {
    return this.ky.put('user/fee/', { json: data }).json<SellerFee>()
  }

  // Auth methods
  login(data: ObtainTokenRequest) {
    return this.ky.post('auth/login/', { json: data }).json<ObtainToken>()
  }

  logout() {
    return this.ky.post('user/logout/', { credentials: 'include' }).json<void>()
  }

  refreshToken() {
    return this.ky.post('auth/refresh/').json<RefreshToken>()
  }

  async refreshTokenAppRouter({
    rotate,
  }: {
    /**
     * This removes the rotating of the token that caused some cases of the user being stuck with an invalid token. Causing 401s and session issues.
     * The drawback is that the user will be logged out after a week. When the refresh token expires.
     */
    rotate?: 'false'
  }) {
    if (!this.refresh) {
      throw new TypeError('Refresh token not set')
    }

    const urlSearchParams = new URLSearchParams()
    if (rotate !== undefined) {
      urlSearchParams.set('rotate', rotate)
    }

    const response = await this.ky.post<RefreshToken>(
      `auth/refresh/?${urlSearchParams.toString()}`,
      { credentials: 'include' },
    )
    const responseCookies = parse(response.headers.get('set-cookie') ?? '')
    const refreshTokenResponse = responseCookies[this.refresh.cookieName]
    if (!refreshTokenResponse) {
      throw new Error(
        'Refresh token cookie not found in auth/refresh/ response',
      )
    }
    const responseJson = await response.json()
    return {
      refreshToken: refreshTokenResponse,
      ...responseJson,
    }
  }

  validateToken(data: ValidateTokenRequest) {
    return this.ky
      .post('auth/token/validate/', {
        json: data,
      })
      .json<ValidateTokenResponse>()
  }

  //Buyers methods

  // Mondu Buy Now, Pay Later methods
  startMonduApplication(data: MonduApplicationRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              const errorBody = await error.response.clone().json()

              const parsedErrorBodyResult =
                startMonduApplicationUnprocessableContentErrorSchema.safeParse(
                  errorBody,
                )
              if (parsedErrorBodyResult.success) {
                return new StartMonduApplicationUnprocessableContentError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }

              return error
            },
          ],
        },
      })
      .post('mondu/apply/', { json: data })
      .json<MonduApplication>()
  }

  // Vat methods
  validateVat(data: VatValidationPostRequestBodyRequest) {
    return this.ky
      .extend({
        hooks: {
          afterResponse: [
            // We can end up with 429 rate limiting on this endpoint very easily as we use it during form validation,
            // If we get a 429, we don't want the client to keep retrying, so we'll convert it to a validity response of 'UNABLE_TO_VALIDATE'
            // which is the same we would get if the service was down, and so the client won't retry
            (_request, _options, response) => {
              if (response.status === 429) {
                const newResponseBody: VatValidationPostResponse = {
                  countryCode: data.countryCode,
                  taxNumber: data.taxNumber,
                  companyName: '',
                  validationStatus: 'UNABLE_TO_VALIDATE',
                }
                return new Response(JSON.stringify(newResponseBody), {
                  status: 200,
                  headers: response.headers,
                })
              }
            },
          ],
        },
      })
      .post('vat/validation/', { json: data })
      .json<VatValidationPostResponse>()
  }
  getVat() {
    return this.ky.get('vat/').json<VATList>()
  }

  // Brand methods
  getBrands(params: {
    page?: number
    size?: number
    premium?: boolean
    slugs?: string[]
  }) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.premium) {
      searchParams.append('premium', String(params.premium))
    }
    if (params.slugs) {
      params.slugs.forEach((slug) => {
        searchParams.append('slug', slug)
      })
    }
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) return error
              const invalidSlugsSchema = z.object({
                code: z.literal('invalid_slugs'),
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                invalidSlugsSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new InvalidSlugsError(error)
              }
              return error
            },
          ],
        },
      })
      .get(`brands/?${searchParams.toString()}`)
      .json<PaginatedBrandList>()
  }
  getBrandContacts({
    page,
    size,
    search,
  }: { page?: number; size?: number; search?: string } = {}) {
    const searchParams = new URLSearchParams()

    if (page) {
      searchParams.append('page', String(page))
    }

    if (size) {
      searchParams.append('size', String(size))
    }

    if (search) {
      searchParams.append('search', search)
    }

    return this.ky
      .get(`brands/contact/?${searchParams.toString()}`)
      .json<PaginatedBrandContactList>()
  }
  getBrandRecommendations(slug: string) {
    return this.ky
      .get(`brands/${slug}/recommendations/`)
      .json<RecommendedBrandsGetResponse>()
  }
  createBrandsRequest(data: BrandRequestRequest[]) {
    return this.ky.post('brands/request/', { json: data }).json<void>()
  }

  // Cart methods
  getCarts({ page, size }: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (page) {
      searchParams.append('page', String(page))
    }
    if (size) {
      searchParams.append('size', String(size))
    }
    return this.ky
      .get(`carts/${searchParams.toString()}`)
      .json<PaginatedCartSummaryList>()
  }
  getActiveCart() {
    return this.ky.get(`carts/active/`).json<Cart>()
  }
  getActiveCartLines(params: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .get(`carts/active/lines/?${searchParams.toString()}`)
      .json<PaginatedCartLineList>()
  }
  getActiveCartDroppedLines(params: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .get(`carts/active/dropped-lines/?${searchParams.toString()}`)
      .json<PaginatedCartLineList>()
  }
  getActiveCartAllocations() {
    return this.ky.get(`carts/active/allocations/`).json<CartAllocationList>()
  }
  emptyActiveCart() {
    return this.ky.post(`carts/active/empty/`).json<Cart>()
  }
  createCartLine({
    data: { gtin, offerQid, dealId, ...data },
  }: {
    data: Omit<CartLineRequest, 'gtin' | 'offerQid' | 'dealId'> &
      // this two conditions make sure that we are not passing invalid data combinations to the BE. we can pass either a gtin or a offerQid and we can pass only the dealId if we pass a gtin
      (| {
            offerQid: CartLineRequest['offerQid']
            gtin?: never
            dealId?: never
          }
        | {
            offerQid?: never
            gtin: CartLineRequest['gtin']
            dealId: CartLineRequest['dealId']
          }
      )
  }) {
    // TODO: Get error schema from backend API instead of hardcoding it here
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              const errorBody = await error.response.clone().json()

              const offersBasedPricingModelSchema = z.object({
                allocationLineQuantity: z.number(),
                availableQuantity: z.number(),
              })
              const parsedOffersBasedPricingModelResult =
                offersBasedPricingModelSchema.safeParse(errorBody)

              if (parsedOffersBasedPricingModelResult.success) {
                return new OffersBasedPricingModelCartLineQuantityExceededError(
                  error,
                  {
                    allocationLineQuantity:
                      parsedOffersBasedPricingModelResult.data
                        .allocationLineQuantity,
                    availableQuantity:
                      parsedOffersBasedPricingModelResult.data
                        .availableQuantity,
                  },
                )
              }

              const cartLineQuantityExceededSchema = z.object({
                availableQuantity: z.number(),
                cartLineQuantity: z.number().optional(),
                allocationLineQuantity: z.number().optional(),
              })
              const parsedErrorBodyResult =
                cartLineQuantityExceededSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new CartLineQuantityExceededError(error, {
                  availableQuantity:
                    parsedErrorBodyResult.data.availableQuantity,
                  cartLineQuantity: parsedErrorBodyResult.data.cartLineQuantity,
                })
              }

              return error
            },
          ],
        },
      })
      .post(`carts/active/lines/`, {
        json: {
          ...data,
          ...(offerQid ? { offerQid } : { gtin, dealId }),
        },
      })
      .json<CartLine>()
  }
  updateCartLine({
    lineQid,
    data,
  }: {
    lineQid: string
    data: PatchedCartLinePatchRequest
  }) {
    return this.ky
      .patch(`carts/active/lines/${lineQid}/`, { json: data })
      .json<CartLine>()
  }
  async deleteCartLine({ lineQid }: { lineQid: string }) {
    return this.ky.delete(`carts/active/lines/${lineQid}/`).json<void>()
  }
  optimizeCart(data: OptimizeCartRequest) {
    return this.ky
      .post(`carts/active/optimize/`, { json: data })
      .json<Checkout>()
  }
  createCartAllocationLine({
    allocationQid,
    data,
  }: {
    allocationQid: string
    data: CartAllocationLineRequest
  }) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              const errorBody = await error.response.clone().json()

              const cartLineQuantityExceededSchema = z.object({
                availableQuantity: z.number(),
                allocationLineQuantity: z.number().optional(),
              })
              const parsedErrorBodyResult =
                cartLineQuantityExceededSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new CartAllocationLineQuantityExceededError(error, {
                  availableQuantity:
                    parsedErrorBodyResult.data.availableQuantity,
                  allocationLineQuantity:
                    parsedErrorBodyResult.data.allocationLineQuantity,
                })
              }

              const cartAllocationLineUnitSizeInvalidSchema = z.object({
                message: z.literal('Unit size invalid.'),
                unit: z.number(),
              })

              const unitSizeInvalidSchemaResult =
                cartAllocationLineUnitSizeInvalidSchema.safeParse(errorBody)
              if (unitSizeInvalidSchemaResult.success) {
                return new CartAllocationLineUnitSizeInvalidError(error, {
                  unitSize: unitSizeInvalidSchemaResult.data.unit,
                })
              }

              return error
            },
          ],
        },
      })
      .post(`carts/active/allocations/${allocationQid}/lines/`, {
        json: data,
      })
      .json<CartAllocationChange>()
  }
  updateCartAllocationLine({
    allocationQid,
    lineQid,
    data,
  }: {
    allocationQid: string
    lineQid: string
    data: PatchedCartAllocationLineRequest
  }) {
    return this.ky
      .patch(`carts/active/allocations/${allocationQid}/lines/${lineQid}/`, {
        json: data,
      })
      .json<CartAllocationChange>()
  }
  deleteCartAllocationLine({
    allocationQid,
    lineQid,
  }: {
    allocationQid: string
    lineQid: string
  }) {
    return this.ky
      .delete(`carts/active/allocations/${allocationQid}/lines/${lineQid}/`)
      .json<void>()
  }
  uploadCart(data: UploadCartRequest) {
    const formData = new FormData()
    formData.append('fileKey', data.fileKey)
    if (data.origin) {
      formData.append('origin', data.origin)
    }
    return this.ky.post(`carts/active/upload/`, { body: formData }).json<Cart>()
  }

  // Category methods
  getCategories(
    params: { page?: number; size?: number; slugs?: string[] } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.slugs) {
      params.slugs.forEach((slug) => searchParams.append('slug', slug))
    }
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) return error
              const errorBody = await error.response.clone().json()
              const invalidSlugsSchema = z.object({
                code: z.literal('invalid_slugs'),
              })
              const parsedErrorBodyResult =
                invalidSlugsSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new InvalidSlugsError(error)
              }
              return error
            },
          ],
        },
      })
      .get(`categories/?${searchParams.toString()}`)
      .json<PaginatedCategoryResponseList>()
  }

  // Checkout methods
  getCheckout() {
    return this.ky.get(`checkouts/active/`).json<Checkout>()
  }
  getCheckoutLines(params: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              return error
            },
          ],
        },
      })
      .get(`checkouts/active/lines/?${searchParams.toString()}`)
      .json<PaginatedCheckoutLineList>()
  }
  updateCheckout(data: PatchedCheckoutRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 400) return error
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                updateCheckoutBadRequestErrorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new UpdateCheckoutBadRequestError(
                  error,
                  parsedErrorBodyResult.data,
                )
              }
              return error
            },
          ],
        },
      })
      .patch(`checkouts/active/`, { json: data })
      .json<Checkout>()
  }
  validateCheckout() {
    return this.ky
      .post<CheckoutValidationResponse>(`checkouts/active/validate/`)
      .json()
  }
  completeCheckout(data: CheckoutCompleteRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              const handledErrorSchema = z.object({
                code: z.enum(['buyer_cannot_checkout']),
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                handledErrorSchema.safeParse(errorBody)

              if (!parsedErrorBodyResult.success) {
                return error
              }

              const { code } = parsedErrorBodyResult.data

              switch (code) {
                case 'buyer_cannot_checkout': {
                  return new BuyerCannotCheckoutError(error)
                }
              }
            },
          ],
        },
      })
      .post(`checkouts/active/complete/`, { json: data })
      .json<CheckoutCompleteResponse>()
  }

  // Claim methods
  getClaim(qid: string) {
    return this.ky.get(`claims/${qid}/`).json<Claim>()
  }
  createClaim(data: ClaimRequest) {
    return this.ky.post<Claim>('claims/', { json: data }).json()
  }
  validateClaimEvidence(data: ClaimEvidenceQuestionEvaluationRequestRequest) {
    return this.ky
      .post<ClaimEvidenceQuestionEvaluationResponse>(
        'claims/evidence-evaluation/',
        {
          json: data,
        },
      )
      .json()
  }

  // Email methods
  validateEmail(data: EmailValidationPostRequest) {
    return this.ky
      .post('email/validation/', { json: data })
      .json<EmailValidationPostResponse>()
  }

  // Order methods
  getOrders(
    params: {
      buyerOrderStatus?: BuyerOrderStatus[]
      dueBeforeAfter?: Date
      dueBeforeBefore?: Date
      fid?: string
      order?: `${'' | '-'}${'fid' | 'total' | 'submitted_at'}`
      page?: number
      search?: string
      size?: number
      status?: OrderStatus[]
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.buyerOrderStatus) {
      params.buyerOrderStatus.forEach((status) => {
        searchParams.append('buyer_order_status', status)
      })
    }
    if (params.dueBeforeAfter) {
      searchParams.append(
        'due_before_after',
        params.dueBeforeAfter.toISOString(),
      )
    }
    if (params.dueBeforeBefore) {
      searchParams.append(
        'due_before_before',
        params.dueBeforeBefore.toISOString(),
      )
    }
    if (params.fid) {
      searchParams.append('fid', params.fid)
    }
    if (params.order) {
      searchParams.append('order', params.order)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.search) {
      searchParams.append('search', params.search)
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.status) {
      params.status.forEach((status) => {
        searchParams.append('status', status)
      })
    }
    return this.ky
      .get(`orders/?${searchParams.toString()}`)
      .json<PaginatedOrderSummaryList>()
  }
  getOrder(qid: string) {
    return this.ky.get(`orders/${qid}/`).json<OrderRetrieve>()
  }
  /** Creates a new cart using this order's items */
  reorder(orderQid: string) {
    return this.ky.post(`orders/${orderQid}/reorder/`).json<Cart>()
  }
  getOrderSales(
    qid: string,
    params: {
      gtin?: string
      name?: string
      order?: string
      page?: number
      size?: number
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.gtin) {
      searchParams.append('gtin', params.gtin)
    }
    if (params.name) {
      searchParams.append('name', params.name)
    }
    if (params.order) {
      searchParams.append('order', params.order)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .get(`orders/${qid}/sales/?${searchParams.toString()}`)
      .json<PaginatedOrderSalesList>()
  }
  getOrderLines(
    qid: string,
    params: {
      gtin?: string
      name?: string
      order?: string
      page?: number
      size?: number
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.gtin) {
      searchParams.append('gtin', params.gtin)
    }
    if (params.name) {
      searchParams.append('name', params.name)
    }
    if (params.order) {
      searchParams.append('order', params.order)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .get(`orders/${qid}/lines/?${searchParams.toString()}`)
      .json<PaginatedOrderLineShippedQuantitiesList>()
  }
  getOrderClaims(qid: string, params: { order?: string } = {}) {
    const searchParams = new URLSearchParams()
    if (params.order) {
      searchParams.append('order', params.order)
    }
    return this.ky
      .get(`orders/${qid}/claims/?${searchParams.toString()}`)
      .json<OrderClaim[]>()
  }
  createOrderIssue(qid: string, data: OrderSaleIssueInputRequest) {
    return this.ky.post(`orders/${qid}/issue/`, { json: data }).json<void>()
  }

  // Variant methods
  getVariantsSearch(
    params: {
      query?: string
      page?: number
      size?: number
      hasDeals?: boolean
      brands?: string[]
      categories?: string[]
      minPrice?: number
      maxPrice?: number
      country?: Country
      recommendationsForGtin?: string
      stockAvailability?: 'in_stock' | 'out_of_stock' | 'all'
      cartAllocationQid?: string
      showWatchlistedOnly?: boolean
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.hasDeals) {
      searchParams.append('has_deals', String(params.hasDeals))
    }
    if (params.brands) {
      params.brands.forEach((brand) => {
        searchParams.append('brand_name', brand)
      })
    }
    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    if (params.stockAvailability) {
      searchParams.append('stock_availability', params.stockAvailability)
    }
    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }
    if (params.showWatchlistedOnly) {
      searchParams.append(
        'show_watchlisted_only',
        String(params.showWatchlistedOnly),
      )
    }

    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 404) return error

              /**
               * The backend uses a 404 for a search with an not existing category.
               * We are checking for the code not_found in order not to throw in case of this particular error
               *   {
               *     "message": "Category with name jewellery-cleaning-care321 does not exist.",
               *     "code": "not_found"
               *   }
               */
              const variantSearchWithNonExistingCategorySchema = z.object({
                code: z.literal('not_found'),
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                variantSearchWithNonExistingCategorySchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new VariantSearchWithNonExistingCategoryError(error)
              }

              return error
            },
          ],
        },
      })
      .get(`variants/search/?${searchParams.toString()}`)
      .json<PaginatedSearch>()
  }
  getVariantsOffers({ fid, slug }: { fid: string; slug: string }) {
    return this.ky
      .get(`variants/${fid}/${slug}/offers/`)
      .json<VariantOffersList>()
  }

  getVariantsOffersSearch(
    params: {
      query?: string
      page?: number
      size?: number
      brands?: string[]
      categories?: string[]
      minPrice?: number
      maxPrice?: number
      country?: Country
      cartAllocationQid?: string
      showWatchlistedOnly?: boolean
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }

    if (params.brands) {
      params.brands.forEach((brand) => {
        searchParams.append('brand_name', brand)
      })
    }
    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.country) {
      searchParams.append('country', params.country)
    }

    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }
    if (params.showWatchlistedOnly) {
      searchParams.append(
        'show_watchlisted_only',
        String(params.showWatchlistedOnly),
      )
    }

    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 404) return error

              /**
               * The backend uses a 404 for a search with an not existing category.
               * We are checking for the code not_found in order not to throw in case of this particular error
               *   {
               *     "message": "Category with name jewellery-cleaning-care321 does not exist.",
               *     "code": "not_found"
               *   }
               */
              const variantSearchWithNonExistingCategorySchema = z.object({
                code: z.literal('not_found'),
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                variantSearchWithNonExistingCategorySchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new VariantSearchWithNonExistingCategoryError(error)
              }

              return error
            },
          ],
        },
      })
      .get(`variants/offers/search/?${searchParams.toString()}`)
      .json<PaginatedOffersSearch>()
  }

  getVariantsSearchForSupplier(
    params: {
      query?: string
      page?: number
      size?: number
      hasDeals?: boolean
      brands?: string[]
      categories?: string[]
      minPrice?: number
      maxPrice?: number
      country?: Country
      recommendationsForGtin?: string
      stockAvailability?: 'in_stock' | 'out_of_stock' | 'all'
      cartAllocationQid?: string
      showWatchlistedOnly?: boolean
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.hasDeals) {
      searchParams.append('has_deals', String(params.hasDeals))
    }
    if (params.brands) {
      params.brands.forEach((brand) => {
        searchParams.append('brand_name', brand)
      })
    }
    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    if (params.stockAvailability) {
      searchParams.append('stock_availability', params.stockAvailability)
    }
    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }
    if (params.showWatchlistedOnly) {
      searchParams.append(
        'show_watchlisted_only',
        String(params.showWatchlistedOnly),
      )
    }

    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status !== 404) return error

              /**
               * The backend uses a 404 for a search with an not existing category.
               * We are checking for the code not_found in order not to throw in case of this particular error
               *   {
               *     "message": "Category with name jewellery-cleaning-care321 does not exist.",
               *     "code": "not_found"
               *   }
               */
              const variantSearchWithNonExistingCategorySchema = z.object({
                code: z.literal('not_found'),
              })
              const errorBody = await error.response.clone().json()
              const parsedErrorBodyResult =
                variantSearchWithNonExistingCategorySchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new VariantSearchWithNonExistingCategoryError(error)
              }

              return error
            },
          ],
        },
      })
      .get(
        `variants/offers/search/${params.cartAllocationQid}?${searchParams.toString()}`,
      )
      .json<PaginatedOffersRecommendationsSearch>()
  }

  getVariantsSearchSuggestions(
    params: {
      cartAllocationQid?: string
      country?: Country
      query?: string
      recommendationsForGtin?: string
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }
    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    return this.ky
      .get(`variants/search/suggestions/?${searchParams.toString()}`)
      .json<SearchSuggestions>()
  }

  getVariantsOffersSearchSuggestions(
    params: {
      country?: Country
      query?: string
      recommendationsForGtin?: string
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    return this.ky
      .get(`variants/offers/search/suggestions/?${searchParams.toString()}`)
      .json<SearchSuggestions>()
  }

  getVariantsOffersSearchSupplierSuggestions(
    cartAllocationQid: string,
    params: {
      country?: Country
      query?: string
      recommendationsForGtin?: string
    } = {},
  ) {
    const searchParams = new URLSearchParams()

    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    return this.ky
      .get(
        `variants/offers/search/${cartAllocationQid}/suggestions/?${searchParams.toString()}`,
      )
      .json<SearchSuggestions>()
  }

  getVariantsSearchFacetsBrandName(
    params: {
      brandQuery?: string
      query?: string
      categories?: string[]
      country?: Country
      hasDeals?: boolean
      maxPrice?: number
      minPrice?: number
      recommendationsForGtin?: string
      stockAvailability?: 'in_stock' | 'out_of_stock' | 'all'
      showWatchlistedOnly?: boolean
      cartAllocationQid?: string
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.brandQuery) {
      searchParams.append('brand_query', params.brandQuery)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }

    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }

    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.hasDeals) {
      searchParams.append('has_deals', String(params.hasDeals))
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.recommendationsForGtin) {
      searchParams.append(
        'recommendations_for_gtin',
        params.recommendationsForGtin,
      )
    }
    if (params.stockAvailability) {
      searchParams.append('stock_availability', params.stockAvailability)
    }
    if (params.showWatchlistedOnly) {
      searchParams.append('show_watchlisted_only', 'true')
    }
    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }

    return this.ky
      .get(`variants/search/facets/brand_name/?${searchParams.toString()}`)
      .json<BrandNameFacetSearch>()
  }
  getVariantsOffersSearchFacetsBrand(
    params: {
      brandQuery?: string
      query?: string
      categories?: string[]
      country?: Country
      maxPrice?: number
      minPrice?: number
      showWatchlistedOnly?: boolean
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.brandQuery) {
      searchParams.append('brand_query', params.brandQuery)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }

    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }

    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.showWatchlistedOnly) {
      searchParams.append('show_watchlisted_only', 'true')
    }

    return this.ky
      .get(`variants/offers/search/facets/brand/?${searchParams.toString()}`)
      .json<BrandNameFacetSearch>()
  }
  getVariantsOffersSearchCartAllocationQidFacetsBrand(
    cartAllocationQid: string,
    params: {
      brandQuery?: string
      query?: string
      categories?: string[]
      country?: Country
      maxPrice?: number
      minPrice?: number
      showWatchlistedOnly?: boolean
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.brandQuery) {
      searchParams.append('brand_query', params.brandQuery)
    }
    if (params.query) {
      searchParams.append('query', params.query)
    }

    if (params.categories) {
      params.categories.forEach((category) => {
        searchParams.append('category_slug', category)
      })
    }
    if (params.country) {
      searchParams.append('country', params.country)
    }
    if (params.maxPrice) {
      searchParams.append('max_price', String(params.maxPrice))
    }
    if (params.minPrice) {
      searchParams.append('min_price', String(params.minPrice))
    }
    if (params.showWatchlistedOnly) {
      searchParams.append('show_watchlisted_only', 'true')
    }

    return this.ky
      .get(
        `variants/offers/search/${cartAllocationQid}/facets/brand/?${searchParams.toString()}`,
      )
      .json<BrandNameFacetSearch>()
  }
  getVariantByFidAndSlug(
    { fid, slug }: { fid: string; slug: string },
    params: { cartAllocationQid?: string; country?: string } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.cartAllocationQid) {
      searchParams.append('cart_allocation_qid', params.cartAllocationQid)
    }
    return this.ky
      .get(`variants/${fid}/${slug}/?${searchParams.toString()}`)
      .json<VariantWeb>()
  }
  getVariantPriceHistory({ fid, slug }: { fid: string; slug: string }) {
    return this.ky
      .get(`variants/${fid}/${slug}/price-history/`)
      .json<PriceHistory[]>()
  }
  createVariantsRequest(data: VariantRequestRequest[]) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              const errorBody = await error.response.clone().json()
              // We get given back an array of either objects with gtin errors, or empty objects
              // for items that passed validation, it's not very conventient to work with
              // so transform this into just an array of the invalid gtins
              const errorSchema = z
                .array(
                  z.union([
                    z.object({
                      gtin: z.object({
                        code: z.literal('invalid_gtin_provided'),
                        gtin: z.string(),
                      }),
                    }),
                    z.object({}),
                  ]),
                )
                .transform((items) =>
                  items
                    .map((item) => ('gtin' in item ? item.gtin.gtin : null))
                    .filter((item) => item !== null),
                )

              const parsedErrorBodyResult = errorSchema.safeParse(errorBody)
              if (parsedErrorBodyResult.success) {
                return new InvalidGtinsProvidedError(error, {
                  invalidGtins: parsedErrorBodyResult.data,
                })
              }

              return error
            },
          ],
        },
      })
      .post(`variants/request/`, { json: data })
      .json<void>()
  }
  createVariantsSearchDownload(data: SearchDownloadRequest) {
    return this.ky
      .post(`variants/search/download/`, { json: data })
      .json<void>()
  }

  // Wallet methods
  getWallet(qid: string) {
    return this.ky.get(`wallets/${qid}/`).json<Wallet>()
  }
  getWalletLines(
    qid: string,
    params: { page?: number; size?: number; source?: string } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.source) {
      searchParams.append('source', params.source.toUpperCase())
    }
    return this.ky
      .get(`wallets/${qid}/lines/?${searchParams.toString()}`)
      .json<PaginatedWalletLineList>()
  }
  applyWallet(qid: string, data: CreateMembershipRequest) {
    return this.ky.post(`wallets/${qid}/apply/`, { json: data }).json<Wallet>()
  }
  withdrawWallet(qid: string, data: WalletWithdrawalRequest) {
    return this.ky
      .post(`wallets/${qid}/withdraw/`, { json: data })
      .json<Wallet>()
  }

  // Watchlist methods
  getWatchlistItems(
    params: {
      page?: number
      size?: number
      areTargetsMet?: boolean
      isAvailable?: boolean
      order?: `${'' | '-'}${'available_quantity' | 'date_added' | 'price' | 'target_price' | 'title'}`
      search?: string
    } = {},
  ) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    if (params.areTargetsMet) {
      searchParams.append('are_targets_met', String(params.areTargetsMet))
    }
    if (params.isAvailable) {
      searchParams.append('is_available', String(params.isAvailable))
    }
    if (params.order) {
      searchParams.append('order', params.order)
    }
    if (params.search) {
      searchParams.append('search', params.search)
    }
    return this.ky
      .get(`watchlist/items/?${searchParams.toString()}`)
      .json<PaginatedWatchlistItemList>()
  }
  deleteWatchlistItems() {
    return this.ky.delete(`watchlist/items/`).json<void>()
  }
  createWatchlistItem(data: WatchlistItemCreateRequest) {
    return this.ky
      .post('watchlist/items/', { json: data })
      .json<WatchlistItem>()
  }
  updateWatchlistItem({
    gtin,
    data,
  }: {
    gtin: string
    data: PatchedWatchlistItemUpdateRequest
  }) {
    return this.ky
      .patch(`watchlist/items/${gtin}/`, { json: data })
      .json<WatchlistItem>()
  }
  deleteWatchlistItem(gtin: string) {
    return this.ky.delete(`watchlist/items/${gtin}/`).json<void>()
  }
  uploadWatchlist(data: UploadWatchlistRequest) {
    return this.ky
      .post(`watchlist/upload/`, { json: data })
      .json<PaginatedWatchlistItemList>()
  }

  // Seller methods
  getSellerRecommendationsLatest() {
    return this.ky
      .get(`seller-recommendations/latest/`)
      .json<SellerRecommendationResponse>()
  }

  // Offer methods
  getOffer(offerQid: string) {
    return this.ky.get(`offers/${offerQid}/`).json<Offer>()
  }

  getOffers(params: {
    page?: number
    size?: number
    status?: string[]
    brands?: string[]
    percentageToWinningPrice?: {
      min: number
      max: number
    }
    sort?:
      | 'winning_price'
      | 'percentage_to_winning_price'
      | 'price'
      | 'inventory'
      | '-winning_price'
      | '-percentage_to_winning_price'
      | '-price'
      | '-inventory'
      | 'rank'
      | '-rank'
  }) {
    const searchParams = new URLSearchParams()
    if (params.status?.length) {
      params.status.forEach((status) => {
        if (status) searchParams.append('status', status)
      })
    }
    if (params.brands?.length) {
      params.brands.forEach((brand) => {
        if (brand) searchParams.append('brand', brand)
      })
    }
    if (params.percentageToWinningPrice) {
      searchParams.set(
        'percentage_to_winning_price_min',
        params.percentageToWinningPrice.min.toString(),
      )
      searchParams.set(
        'percentage_to_winning_price_max',
        params.percentageToWinningPrice.max.toString(),
      )
    }
    if (params.sort) {
      switch (params.sort) {
        case '-winning_price': {
          searchParams.set('order', '-winning_price_seller_currency')
          break
        }
        case 'winning_price': {
          searchParams.set('order', 'winning_price_seller_currency')
          break
        }
        case 'percentage_to_winning_price': {
          searchParams.set('order', 'discount_required')
          break
        }
        case '-percentage_to_winning_price': {
          searchParams.set('order', '-discount_required')
          break
        }
        case '-price': {
          searchParams.set('order', '-original_price')
          break
        }
        case 'price': {
          searchParams.set('order', 'original_price')
          break
        }
        case '-rank': {
          searchParams.set('order', '-rank')
          break
        }
        case 'rank': {
          searchParams.set('order', 'rank')
          break
        }

        default: {
          searchParams.set('order', params.sort)
        }
      }
    }

    if (params.page) {
      searchParams.set('page', params.page.toString())
    }

    if (params.size) {
      searchParams.set('size', params.size.toString())
    }

    return this.ky.get(`offers/`, { searchParams }).json<PaginatedOfferList>()
  }

  getOffersSearch(query: string) {
    const searchParams = new URLSearchParams()
    searchParams.append('search', query)
    return this.ky
      .get(`offers/search/?${searchParams.toString()}`)
      .json<OfferSearch[]>()
  }

  getOffersInfo() {
    return this.ky.get(`offers/info/`).json<OfferInfo>()
  }

  getOffersSellerBrands() {
    return this.ky.get(`offers/seller/brands/`).json<Brand[]>()
  }

  requestOffersCatalog() {
    return this.ky.post(`offers/catalog/`).json<void>()
  }

  getOffersBrandDiscounts() {
    return this.ky
      .get(`offers/brand-discounts/`)
      .json<BrandDiscountResponse[]>()
  }

  createOfferBrandDiscounts(data: BrandDiscountRequestRequest[]) {
    return this.ky
      .post(`offers/brands-discounts/upsert-multiple/`, { json: data })
      .json<void>()
  }

  deleteOfferBrandDiscount(qid: string) {
    return this.ky.delete(`offers/brand-discount/${qid}/`).json<void>()
  }

  getOffersBrandsWithProducts() {
    return this.ky
      .get(`offers/brands-with-products/`)
      .json<BrandsWithSellerDiscountsResponse[]>()
  }

  getOffersTotalCountByStatus() {
    return this.ky
      .get(`offers/total-count-by-status/`)
      .json<OfferCountByStatusStatus>()
  }

  // Seller lead methods
  getSellerLead(slug: string) {
    return this.ky.get(`sellerleads/${slug}/`).json<SellerLead>()
  }

  getSellerLeads() {
    return this.ky.get(`sellerleads/`).json<SellerLeadListItem[]>()
  }

  createSellerLead(variables: CreateSellerLeadRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const emailAlreadyUsed = z.object({
                  email: z.string().array().or(z.string()),
                })
                const hasError = emailAlreadyUsed.safeParse(
                  await error.response.clone().json(),
                )
                if (hasError.success) {
                  return new SellerLeadsEmailAlreadyUsedError(error)
                }
              }

              return error
            },
          ],
        },
      })
      .post(`sellerleads/`, { json: variables })
      .json<SellerLead>()
  }

  // sellers methods
  getSellersStats() {
    return this.ky.get(`sellers/stats/`).json<SellerStatsOutput>()
  }

  // Sales methods
  getSale(qid: string) {
    return this.ky.get(`sales/${qid}/`).json<Sale>()
  }

  getSales(params: {
    status?: Sale['status'][]
    order?:
      | 'committed_total'
      | '-committed_total'
      | 'expired_at'
      | '-expired_at'
      | 'paid_at'
      | '-paid_at'
    page?: number
    hasSalelineQuantitiesConfirmed?: boolean
    hasShipments?: boolean
    hasBookedWithQogita?: boolean
    hasInvoiceForDownload?: boolean
    hasRead?: boolean
    expiredFrom?: string
    destination?: string
  }) {
    const searchParams = new URLSearchParams()

    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.order) {
      searchParams.append('order', String(params.order))
    }
    if (params.expiredFrom) {
      searchParams.append('expiredFrom', String(params.expiredFrom))
    }
    if (params.hasRead !== undefined) {
      searchParams.append('hasRead', String(params.hasRead))
    }
    if (params.hasShipments !== undefined) {
      searchParams.append('has_shipments', String(params.hasShipments))
    }
    if (params.hasSalelineQuantitiesConfirmed !== undefined) {
      searchParams.append(
        'has_saleline_quantities_confirmed',
        String(params.hasSalelineQuantitiesConfirmed),
      )
    }
    if (params.hasBookedWithQogita !== undefined) {
      searchParams.append(
        'has_booked_with_qogita',
        String(params.hasBookedWithQogita),
      )
    }
    if (params.hasInvoiceForDownload !== undefined) {
      searchParams.append(
        'has_invoice_for_download',
        String(params.hasInvoiceForDownload),
      )
    }
    if (params.destination) {
      searchParams.append('destination', params.destination)
    }
    if (params.status) {
      for (const status of params.status) {
        searchParams.append('status', status)
      }
    }

    return this.ky
      .get(`sales/?${searchParams.toString()}`)
      .json<PaginatedSaleList>()
  }

  getSalesSearch(query: string) {
    const searchParams = new URLSearchParams()
    searchParams.append('search', query)
    return this.ky
      .get(`sales/search/?${searchParams.toString()}`)
      .json<SaleSearch[]>()
  }

  createSaleIssue(qid: string, data: SaleIssueInputRequest) {
    return this.ky.post(`sales/${qid}/issue/`, { json: data }).json<void>()
  }

  confirmSalelineQuantities(qid: string) {
    return this.ky
      .post(`sales/${qid}/confirm_saleline_quantities/`)
      .json<null>()
  }

  updateSaleSalelines(qid: string, data: SaleLinesBulkUpdateInputRequest[]) {
    return this.ky
      .put(`sales/${qid}/lines/`, { json: data })
      .json<SaleLineGenericOutput[]>()
  }

  declineSale(qid: string, data: SaleDeclineRequest) {
    return this.ky.post(`sales/${qid}/decline/`, { json: data }).json<void>()
  }

  getSaleLines(qid: string, params: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (params.page) {
      searchParams.append('page', String(params.page))
    }
    if (params.size) {
      searchParams.append('size', String(params.size))
    }
    return this.ky
      .get(`sales/${qid}/lines/?${searchParams.toString()}`)
      .json<PaginatedSaleLineGenericOutputList>()
  }

  getSaleBookableCarrier(
    qid: string,
    params: {
      /**
       * This param must be set for the backend to behave correctly, hence it's not optional.
       * Related backend code: https://github.com/qogita/canary/blob/f543aee2564b9967aa503057cdd87853f26311e0/canary/sale/views/bookable_carriers.py#L35-L41
       * */
      hasDangerousGoods: boolean
    },
  ) {
    const searchParams = new URLSearchParams()
    searchParams.append(
      'has_dangerous_goods',
      params.hasDangerousGoods ? 'true' : 'false',
    )

    return this.ky
      .get(`sales/${qid}/bookable-carriers/?${searchParams.toString()}`)
      .json<ShippingCarrier[]>()
  }

  getSaleDocuments(qid: string) {
    return this.ky.get(`sales/${qid}/documents/`).json<File[]>()
  }

  updateSaleDocument(
    qid: string,
    params: {
      documentQid: string
      metadata: { documentType: string }
    },
  ) {
    const formBody = new FormData()
    if (params.metadata)
      formBody.append('metadata', JSON.stringify(params.metadata))
    return this.ky
      .patch(`sales/${qid}/document/${params.documentQid}/`, {
        body: formBody,
      })
      .json<File>()
  }

  deleteSaleDocument(qid: string, documentQid: string) {
    return this.ky.delete(`sales/${qid}/document/${documentQid}/`).json<void>()
  }

  createSaleDocument(qid: string, data: FileRequest) {
    const formBody = new FormData()
    formBody.append('file', data.file)
    if (data.metadata) {
      formBody.append('metadata', JSON.stringify(data.metadata))
    }

    return this.ky
      .post(`sales/${qid}/document/`, { body: formBody })
      .json<File>()
  }

  getSaleCarrierBooking(qid: string) {
    return this.ky
      .extend({
        hooks: {
          afterResponse: [
            async (request, options, response) => {
              if (response.status === 404) {
                // We expect sales to not have a carrier booking
                // until they start the ship with Qogita flow
                return new Response(null)
              }
              if (response.ok) {
                const data = await response.clone().json()
                try {
                  z.object({ status: z.literal('SUCCEEDED') }).parse(data)
                  return response
                } catch {
                  // The backend might return a failed carrier booking,
                  // in which case we assume there is no carrier booking
                  // and allow downstream consumers to create a new one
                  return new Response(null)
                }
              }
              return response
            },
          ],
        },
      })
      .get(`sales/${qid}/carrier_booking/`)
      .json<SaleCarrierBookingOutput | null>()
  }

  postSaleRead(qid: string) {
    return this.ky.post(`sales/${qid}/read/`).json<null>()
  }

  updateSale(qid: string, data: PatchedSaleRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const data = await error.response.clone().json()
                const isExceededMaxShippingFeeError = z
                  .object({
                    sellerShippingFee: z
                      .literal('EXCEEDED_MAXIMUM_SHIPPING_FEE_AMOUNT')
                      .array(),
                  })
                  .safeParse(data).success
                if (isExceededMaxShippingFeeError) {
                  return new ExceededMaxShippingFeeError(error)
                }

                const isExceededMaxSellerVatError = z
                  .object({
                    sellerVat: z.object({
                      nonFieldErrors: z.array(
                        z.object({
                          errorCode: z.literal('EXCEEDED_MAXIMUM_VAT_AMOUNT'),
                        }),
                      ),
                    }),
                  })
                  .safeParse(data).success
                if (isExceededMaxSellerVatError) {
                  return new ExceededMaxSellerVatError(error)
                }
              }
              return error
            },
          ],
        },
      })
      .patch(`sales/${qid}/`, { json: data })
      .json<Sale>()
  }

  createSaleCarrierBooking(qid: string, data: SaleCarrierBookingInputRequest) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const data = await error.response.clone().json()
                const isCarrierBookingPickUpDateNotAvailableError = z
                  .object({
                    code: z.literal('pickup_date_not_available_exception'),
                  })
                  .safeParse(data).success

                if (isCarrierBookingPickUpDateNotAvailableError) {
                  return new SaleCarrierBookingPickUpDateNotAvailableError(
                    error,
                  )
                }

                const isSellerShippingAddressTooLongError = z
                  .object({
                    code: z
                      .literal('seller_shipping_address_line_one_too_long')
                      .or(
                        z.literal('seller_shipping_address_line_two_too_long'),
                      ),
                  })
                  .safeParse(data).success

                if (isSellerShippingAddressTooLongError) {
                  return new SaleCarrierBookingSellerShippingAddressTooLongError(
                    error,
                  )
                }
              }
              return error
            },
          ],
        },
      })
      .post(`sales/${qid}/carrier_booking/`, { json: data })
      .json<SaleCarrierBookingOutput>()
  }

  salePayComplete(saleQid: string) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const data = await error.response.clone().json()

                const isMaxShippingFeeError = z
                  .object({
                    price: z.array(
                      z.literal('EXCEEDED_MAXIMUM_SHIPPING_FEE_AMOUNT'),
                    ),
                  })
                  .safeParse(data).success

                if (isMaxShippingFeeError) {
                  return new SalePayCompleteMaxShippingFeeExceededError(error)
                }

                const isSalePayCompleteMissingReductionReasonError = z
                  .object({
                    nonFieldErrors: z
                      .object({
                        erroCode: z.literal(
                          'SALE_LINES_REDUCTION_REASON_REQUIRED',
                        ),
                      })
                      .array(),
                  })
                  .safeParse(data).success

                if (isSalePayCompleteMissingReductionReasonError) {
                  return new SalePayCompleteSalelineMissingReductionReasonError(
                    error,
                  )
                }

                const maxVatExceededResult = z
                  .object({
                    nonFieldErrors: z.array(
                      z.object({
                        errorCode: z.literal('EXCEEDED_MAXIMUM_VAT_AMOUNT'),
                        expectedVatAmount: z.string(),
                      }),
                    ),
                  })
                  .transform((value) => value.nonFieldErrors[0])
                  .safeParse(data)
                if (maxVatExceededResult.success) {
                  return new SalePayCompleteMaxVatExceededError(error, {
                    maxValue: maxVatExceededResult.data?.expectedVatAmount,
                  })
                }
              }

              return error
            },
          ],
        },
      })
      .post(`sales/${saleQid}/paycomplete/`)
      .json<Sale>()
  }

  // Sale lines methods
  updateSaleLine({
    saleQid,
    saleLineQid,
    data,
  }: {
    saleQid: string
    saleLineQid: string
    data: PatchedSaleLineGenericRequest
  }) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const data = await error.response.clone().json()
                const isQuantityExceedsRequestedAmountError = z
                  .object({
                    quantity: z.object({
                      errorCode: z.literal('QUANTITY_EXCEEDS_REQUESTED_AMOUNT'),
                    }),
                  })
                  .safeParse(data).success

                if (isQuantityExceedsRequestedAmountError) {
                  return new SaleLineQuantityExceedsRequestedAmountError(error)
                }
              }
              return error
            },
          ],
        },
      })
      .put(`sales/${saleQid}/lines/${saleLineQid}/`, { json: data })
      .json<SaleLineGenericOutput>()
  }

  // Carriers
  getCarriers({ page, size }: { page?: number; size?: number } = {}) {
    const searchParams = new URLSearchParams()
    if (page) {
      searchParams.append('page', String(page))
    }
    if (size) {
      searchParams.append('size', String(size))
    }
    return this.ky
      .get(`carriers/?${searchParams.toString()}`)
      .json<PaginatedShippingCarrierList>()
  }

  // Sellers methods
  acceptSellersSla() {
    return this.ky.post('sellers/accept-sla/').json<null>()
  }

  getSellerRecommendations() {
    return this.ky.get('seller-recommendations/latest?format=xlsx').blob()
  }

  // Shipment methods
  deleteShipment(qid: string) {
    return this.ky.delete(`shipments/${qid}/`).json<null>()
  }

  createShipments(data: ShipmentInputRequest[]) {
    return this.ky
      .extend({
        hooks: {
          beforeError: [
            async (error) => {
              if (error.response.status === 400) {
                const data = await error.response.clone().json()

                const isInvalidCodeError = z
                  // convert array of objects containing array of invalid codes,
                  // to a single array of unique strings
                  // where the strings are the invalid codes
                  .object({ code: z.string().array().nonempty().nullish() })
                  .array()
                  .nonempty()
                  .transform((data) =>
                    Array.from(
                      new Set(
                        data.flatMap((item) => item.code || '').filter(Boolean),
                      ),
                    ),
                  )
                  .safeParse(data)
                if (isInvalidCodeError.success) {
                  return new InvalidShipmentCodeError(error, {
                    invalidCodes: isInvalidCodeError.data,
                  })
                }
              }
              return error
            },
          ],
        },
      })
      .post('shipments/', { json: data })
      .json<SellerShipmentsResponse>()
  }

  // internal initial check
  createInternalInitialCheck(data: InitialCheckRequestRequest) {
    return this.ky
      .post(`internal/initialcheck/`, { json: data })
      .json<InitialCheckResponse>()
  }

  getInternalInitialCheckTask(id: string) {
    return this.ky.get(`internal/initialcheck/task/${id}/`).json<{
      result: InitialCheckTaskResult
      status: 'SUCCESS' | 'FAILURE'
    }>()
  }
}

// These should all eventually be generated from the OpenAPI spec

export type GetCartsSearchParams = NonNullable<
  Parameters<CanaryClient['getCarts']>[0]
>
export type GetCartLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getActiveCartLines']>[0]
>
export type GetCartDroppedLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getActiveCartDroppedLines']>[0]
>
export type GetCheckoutLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getCheckoutLines']>[0]
>
export type GetOrdersSearchParams = NonNullable<
  Parameters<CanaryClient['getOrders']>[0]
>
export type GetOrderSalesSearchParams = NonNullable<
  Parameters<CanaryClient['getOrderSales']>[1]
>
export type GetOrderLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getOrderLines']>[1]
>
export type GetOrderClaimsSearchParams = NonNullable<
  Parameters<CanaryClient['getOrderClaims']>[1]
>
export type GetVariantByFidAndSlugSearchParams = NonNullable<
  Parameters<CanaryClient['getVariantByFidAndSlug']>[1]
>
export type GetOffersSearchParams = Parameters<CanaryClient['getOffers']>[0]
export type GetSaleLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getSaleLines']>['1']
>
export type GetAddressesSearchParams = NonNullable<
  Parameters<CanaryClient['getAddresses']>[0]
>

export type GetBrandsSearchParams = NonNullable<
  Parameters<CanaryClient['getBrands']>[0]
>

export type GetBrandContactSearchParams = NonNullable<
  Parameters<CanaryClient['getBrandContacts']>[0]
>

export type GetVariantsSearchSearchParams = NonNullable<
  Parameters<CanaryClient['getVariantsSearch']>[0]
>

export type GetVariantsSearchSuggestionsSearchParams = NonNullable<
  Parameters<CanaryClient['getVariantsSearchSuggestions']>[0]
>
export type GetVariantsOffersSearchSupplierSuggestionsParams = NonNullable<
  Parameters<CanaryClient['getVariantsOffersSearchSupplierSuggestions']>[1]
>
export type GetVariantsOffersSearchSuggestionsParams = NonNullable<
  Parameters<CanaryClient['getVariantsOffersSearchSuggestions']>[0]
>
export type GetWalletLinesSearchParams = NonNullable<
  Parameters<CanaryClient['getWalletLines']>[1]
>
export type GetWatchlistItemsSearchParams = NonNullable<
  Parameters<CanaryClient['getWatchlistItems']>[0]
>
export type GetVariantsSearchFacetsBrandNameSearchParams = NonNullable<
  Parameters<CanaryClient['getVariantsSearchFacetsBrandName']>[0]
>
export type GetVariantsOffersSearchCartAllocationQidFacetsBrand = NonNullable<
  Parameters<
    CanaryClient['getVariantsOffersSearchCartAllocationQidFacetsBrand']
  >[1]
>
export type GetCategoriesSearchParams = NonNullable<
  Parameters<CanaryClient['getCategories']>[0]
>

export class CartLineQuantityExceededError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { availableQuantity: number; cartLineQuantity?: number },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CartLineQuantityExceededError'
  }
}

export class OffersBasedPricingModelCartLineQuantityExceededError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: {
      availableQuantity?: number
      allocationLineQuantity?: number
    },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'OffersBasedPricingModelCartLineQuantityExceededError'
  }
}
export class CartAllocationLineQuantityExceededError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: {
      availableQuantity: number
      allocationLineQuantity?: number
    },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CartAllocationLineQuantityExceededError'
  }
}

export class CartAllocationLineUnitSizeInvalidError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { unitSize: number },
  ) {
    super(httpError.response, httpError.request, httpError.options)
  }
}
export class InvalidSlugsError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'BrandsInvalidSlugsError'
  }
}
export class SellerLeadsEmailAlreadyUsedError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SellerLeadsEmailAlreadyUsedError'
  }
}
export class BuyerCannotCheckoutError extends HTTPError {
  constructor(httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'BuyerCannotCheckoutError'
  }
}
export class InvalidGtinsProvidedError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { invalidGtins: string[] },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'InvalidGtinsProvidedError'
  }
}

export class InvalidShipmentCodeError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { invalidCodes: string[] },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'InvalidShipmentCodeError'
  }
}

export class ExceededMaxShippingFeeError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'ExceededMaxShippingFeeError'
  }
}

export class SaleCarrierBookingPickUpDateNotAvailableError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CarrierBookingPickUpDateNotAvailableError'
  }
}

export class SaleCarrierBookingSellerShippingAddressTooLongError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SellerShippingAddressTooLongError'
  }
}

export class SaleLineQuantityExceedsRequestedAmountError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SaleLineQuantityExceedsRequestedAmountError'
  }
}

export class ExceededMaxSellerVatError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'ExceededMaxSellerVatError'
  }
}

export class CreateUserBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { code: string; message: string } & Partial<
      Record<keyof UserCreateRequest, string[]>
    >,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CreateUserBadRequestError'
  }
}

export class SalePayCompleteMaxShippingFeeExceededError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SalePayCompleteMaxShippingFeeExceededError'
  }
}

export class SalePayCompleteSalelineMissingReductionReasonError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SalePayCompleteSalelineMissingReductionReasonError'
  }
}

export class SalePayCompleteMaxVatExceededError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { maxValue?: string },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'SalePayCompleteMaxVatExceededError'
  }
}

const updateUserBadRequestErrorSchema = z.object({
  code: z.literal('invalid'),
  message: z.string(),
  email: z
    .array(
      z.union([
        z
          .literal('User with this Email already exists.')
          .transform(
            (value) => ({ code: 'ALREADY_EXISTS', message: value }) as const,
          ),
        z
          .string()
          .transform((value) => ({ code: 'INVALID', message: value }) as const),
      ]),
    )
    .optional(),
})
export class UpdateUserBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<typeof updateUserBadRequestErrorSchema>,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'UpdateUserBadRequestError'
  }
}

const updateUserPasswordBadRequestErrorSchema = z.object({
  code: z.literal('invalid'),
  message: z.string(),
  secret: z.array(z.literal('Invalid input.')),
})
export class UpdateUserPasswordBadRequestError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'IncorrectPasswordError'
  }
}

const updateCheckoutBadRequestErrorSchema = z.object({
  code: z.literal('invalid'),
  message: z.string(),
  billingAddressQid: z.array(z.string()).optional(),
  shippingAddressQid: z
    .array(
      z.union([
        z.object({
          message: z.string(),
          vatCompanyName: z.string().optional(),
        }),
        z.string(),
      ]),
    )
    .optional(),
  daysDeferred: z.array(z.string()).optional(),
})
export class UpdateCheckoutBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<typeof updateCheckoutBadRequestErrorSchema>,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'UpdateCheckoutBadRequestError'
  }
}

const createAddressBadRequestErrorSchema = z.object({
  code: z.literal('invalid'),
  message: z.string(),
  // We only handle a specific error in company name for now
  // If you need to handle other fields, add them here
  companyName: z
    .array(
      z
        .object({
          message: z.literal(
            'Address company name must include the VAT company name.',
          ),
          vatCompanyName: z.string(),
        })
        .transform((value) => ({
          ...value,
          code: 'VAT_COMPANY_NAME_MISMATCH' as const,
        })),
    )
    .optional(),
})
export class CreateAddressBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<typeof createAddressBadRequestErrorSchema>,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'CreateAddressBadRequestError'
  }
}

const updateAddressBadRequestErrorSchema = createAddressBadRequestErrorSchema
export class UpdateAddressBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<typeof updateAddressBadRequestErrorSchema>,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'UpdateAddressBadRequestError'
  }
}

export class PhoneVerifyThrottleError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: { waitSeconds: number },
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'PhoneVerifyThrottleError'
  }
}

const confirmVerifyUserPhoneBadRequestErrorSchema = z.object({
  code: z.literal('authentication_failed'),
})
export class ConfirmVerifyUserPhoneBadRequestError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<typeof confirmVerifyUserPhoneBadRequestErrorSchema>,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'ConfirmVerifyUserPhoneBadRequestError'
  }
}

const startMonduApplicationUnprocessableContentErrorSchema = z.object({
  code: z.literal('already_applied'),
})

export class StartMonduApplicationUnprocessableContentError extends HTTPError {
  constructor(
    private httpError: HTTPError,
    public details: z.infer<
      typeof startMonduApplicationUnprocessableContentErrorSchema
    >,
  ) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'StartMonduApplicationUnprocessableContentError'
  }
}

export { HTTPError } from 'ky'

export class VariantSearchWithNonExistingCategoryError extends HTTPError {
  constructor(private httpError: HTTPError) {
    super(httpError.response, httpError.request, httpError.options)
    this.name = 'VariantSearchWithNonExistingCategoryError'
  }
}
