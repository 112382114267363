import { Anchor } from '@qogita/ui/anchor'
import { useEffect, useRef } from 'react'

export const MiniTrustBox = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }
    // This work in conjuction with the script loaded in
    // _app.tsx, when it's read window.Trustpilot becomes ready
    // and can be used here to initialise the trustbox
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div className="flex justify-center">
      <div
        ref={ref}
        className="trustpilot-widget h-20"
        data-locale="en-GB"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="61e6a507aa6867337dd09e64"
        data-style-width="150px"
        data-style-height="100%"
        data-text-color="#6B7280"
      >
        <Anchor
          href="https://www.trustpilot.com/review/www.qogita.com"
          target="_blank"
          rel="noopener"
        >
          See our reviews on Trustpilot
        </Anchor>
      </div>
    </div>
  )
}
