import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import {
  DeprecatedBrandsPanelStoryblok,
  DeprecatedBrandStoryblok,
} from 'src/core/cms/storyblok-component-types'
import { CmsPanelErrorBoundary } from 'src/deprecated/components/cms/CmsPanelErrorBoundary'

import { Anchor } from './Anchor'
import { storyblokImageLoader } from './storyblokImageLoader'

const BrandsPanelComponent = ({
  blok,
}: {
  blok: DeprecatedBrandsPanelStoryblok
}) => {
  const cta = blok.cta[0]
  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-center gap-8 bg-white px-4 py-8 md:py-12"
    >
      <h2 className="text-balance text-4xl font-bold">{blok.title}</h2>
      <ul className="grid grid-cols-2 gap-x-6 gap-y-4 sm:grid-cols-3 lg:grid-cols-6">
        {blok.brands.map((brand) => (
          <li key={brand._uid}>
            <StoryblokComponent blok={brand} />
          </li>
        ))}
      </ul>
      {cta ? (
        <div>
          <Anchor
            blok={cta}
            color="primary"
            appearance="text"
            size="small"
            className="w-full md:w-auto"
          >
            <span>&rarr;</span>
          </Anchor>
        </div>
      ) : null}
    </div>
  )
}
export const BrandsPanel = ({
  blok,
}: {
  blok: DeprecatedBrandsPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <BrandsPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}

const BrandCardComponent = ({ blok }: { blok: DeprecatedBrandStoryblok }) => {
  return (
    <div {...storyblokEditable(blok)} className="bg-gray-50">
      <Link href={blok.link.url}>
        <Image
          loader={storyblokImageLoader}
          src={blok.image.filename}
          width={150}
          height={150}
          className="rounded-t-lg"
          alt={blok.image.alt || ''}
        />
        <p className="p-4 text-base font-bold text-gray-700">{blok.name}</p>
      </Link>
    </div>
  )
}
export const BrandCard = ({ blok }: { blok: DeprecatedBrandStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <BrandCardComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
